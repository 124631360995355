import axios from "axios";
import {
    USER_AUTH_REQUEST,
    USER_AUTH_SUCCESS,
    USER_AUTH_FAIL,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL,
    GET_USER_FAIL,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    SERVERADDRESS,
} from "../constants"

// USER LOGIN
export const userLogin = (obj, navigateUser) => async (dispatch) => {
    dispatch({
        type: USER_AUTH_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/auth/login`, obj);
        localStorage.setItem("tokens", JSON.stringify(data.tokens));
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch({
            type: USER_AUTH_SUCCESS,
            payload: data,
        });
        navigateUser();
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: USER_AUTH_FAIL,
            payload: err,
        });
    }
};

//USER LOGOUT
export const userLogout = (obj, navigateUser) => async (dispatch) => {
    try {
      await axios.post(`${SERVERADDRESS}/v1/auth/logout`, obj);
      localStorage.removeItem("tokens");
      localStorage.removeItem("user");
    //   localStorage.removeItem("selectedPackage");
      dispatch({
        type: USER_LOGOUT_SUCCESS,
      });
      navigateUser()
    } catch (err) {
      dispatch({
        type: USER_LOGOUT_FAIL,
        payload: "err.response.data.message",
      });
    }
};

export const getUserByToken = (token, navigate) => async (dispatch) => {
  navigate('/checkUser')
  dispatch({
    type: GET_USER_REQUEST,
    
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/auth/refresh-tokens`, {
      refreshToken: token,
    });
    localStorage.setItem("tokens", JSON.stringify(data));
    dispatch({
      type: GET_USER_SUCCESS,
      payload: data,
    });
    navigate('/dashboard')
  } catch (err) {
    localStorage.clear()
    console.log("err=>", err);
    dispatch({
      type: GET_USER_FAIL,
      payload: err.response.data.message,
    });
    navigate('/')
    console.log(localStorage)
  }
};

  