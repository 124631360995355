import React from 'react'
import { NavLink } from 'react-router-dom'

const LocationProjectDetails = () => {
    return (
        <>
        
            <div className="card">
                <div className="card-body">
                    <h6 className="card-title m-b-15">Project details</h6>
                    <table className="table table-striped table-border">
                        <tbody>
                            <tr>
                                <td>Total Hours:</td>
                                <td className="text-right">100 Hours</td>
                            </tr>
                            <tr>
                                <td>Created:</td>
                                <td className="text-right">25 Feb, 2019</td>
                            </tr>
                            <tr>
                                <td>Deadline:</td>
                                <td className="text-right">12 Jun, 2019</td>
                            </tr>
                            <tr>
                                <td>Priority:</td>
                                <td className="text-right">
                                    <div className="btn-group">
                                        <NavLink to="#" className="badge badge-danger dropdown-toggle" data-toggle="dropdown">Highest </NavLink>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <NavLink className="dropdown-item" to="#"><i className="fa fa-dot-circle-o text-danger"></i> Highest priority</NavLink>
                                            <NavLink className="dropdown-item" to="#"><i className="fa fa-dot-circle-o text-info"></i> High priority</NavLink>
                                            <NavLink className="dropdown-item" to="#"><i className="fa fa-dot-circle-o text-primary"></i> Normal priority</NavLink>
                                            <NavLink className="dropdown-item" to="#"><i className="fa fa-dot-circle-o text-success"></i> Low priority</NavLink>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Status:</td>
                                <td className="text-right">Active</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="m-b-5">Progress <span className="text-success float-right">40%</span></p>
                    <div className="progress progress-xs mb-0">
                        <div className="progress-bar bg-success" role="progressbar" data-toggle="tooltip" title="40%" style={{ width: "40%" }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocationProjectDetails