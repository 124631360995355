import {
    CLOCK_IN_REQUEST,
    CLOCK_IN_SUCCESS,
    CLOCK_IN_FAIL,
    CLOCK_OUT_REQUEST,
    CLOCK_OUT_SUCCESS,
    CLOCK_OUT_FAIL,
    CLOCK_EXIST_REQUEST,
    CLOCK_EXIST_SUCCESS,
    CLOCK_EXIST_FAIL,
    CLOCK_STATUS_REQUEST,
    CLOCK_STATUS_SUCCESS,
    CLOCK_STATUS_FAIL,
    CLOCK_MONTHLY_DATA_REQUEST,
    CLOCK_MONTHLY_DATA_SUCCESS,
    CLOCK_MONTHLY_DATA_FAIL,
} from "../constants"

export const clockReducer = (
    state = {
        clock: {
            clockData: null,
            clockInButton: true,
            breakButton: null,
            clockStatusData: null,
            clockMonthlyData: null,
        }
    },
    action
) => {
    switch (action.type) {
        // USER CLOCK IN
        case CLOCK_IN_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_IN_SUCCESS :
            if (action.payload !== "exists"){
            return {
                ...state,
                clockData: action.payload,
                clockInButton: false,
                breakButton: true,
            };
            }
        case CLOCK_IN_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER CLOCK UPDATE
        case CLOCK_OUT_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_OUT_SUCCESS :
            if (action.payload.clockedIn === false){
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: true,
                    breakButton: null,
                };
            }
            else {
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: false,
                    breakButton: true,
                };
            }
        case CLOCK_OUT_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER CLOCK CHECK
        case CLOCK_EXIST_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_EXIST_SUCCESS :
            if (action.payload.clockedIn === false){
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: true,
                    breakButton: null,
                };
            }
            else {
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: false,
                    breakButton: true,
                };
            }
        case CLOCK_EXIST_FAIL :
            return {
                ...state,
                clockData: null,
                clockInButton: true,
                breakButton: null,
                error: action.payload,
            };
        // MONTHLY AND WEEK CLOCK STATUS OF USER
        case CLOCK_STATUS_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_STATUS_SUCCESS :
            return {
                ...state,
                clockStatusData: action.payload,
            };
        case CLOCK_STATUS_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // MONTHLY CLOCK DATA OF USER
        case CLOCK_MONTHLY_DATA_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_MONTHLY_DATA_SUCCESS :
            return {
                ...state,
                clockMonthlyData: action.payload,
            };
        case CLOCK_MONTHLY_DATA_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}