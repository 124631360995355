import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
// import EmployeeScheduleDaily from './EmployeeScheduleDaily'
// import EmployeeScheduleViewBar from './EmployeeScheduleViewBar'
// import moment from 'moment';
import { shiftPageData } from "../../../actions/shiftActions"
import { useSelector, useDispatch } from 'react-redux'


const EmployeeScheduleCalender = ({weekdate}) => {
    // const [ viewCalender, setViewCalender ] = useState("weekly")
    // const handleDaily = (e) => {
    //     setViewCalender("daily")
    // }
    // const handleWeek = (e) => {
    //     setViewCalender("weekly")
    // }
    // const handleMonthly= (e) => {
    //     setViewCalender("monthly")
    // }
    // const data = JSON.parse(window.localStorage.getItem('weeklyShift'))
    // const [ data, setData ] = useState([{weeklyShift:[{
    //                                     startTime: "", endTime: "", duration: ""
    //                                     }]}])
    const auth = useSelector((state) => state.auth);
    const shift = useSelector((state) => state.shift);
    // console.log(shift, "shiftshiftshiftshiftshiftshift")
    const data = shift.weeklyShift
    let arr = []
    let temp;
    // const shift = useSelector((state) => state.weeklyShift);
    // const dispatch = useDispatch()
    // const today = new Date("2022-07-29");
    // const dt = today.toISOString().substring(0, 10);
    // console.log(shift,"LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")
    // const shiftData = async (e) => {
    //     await dispatch(shiftPageData({userId: auth.user.id, date: dt}))
    // }
    // useEffect(() => {
    //     // shiftData()
    //     setData(JSON.parse(window.localStorage.getItem('weeklyShift')))
    // },[])
    // console.log(data, "weekdate")
    for (let i=0; i<weekdate.length; i++){
        temp = parseInt(weekdate[i].date)
        data.forEach(e => {
            if (e.dateDD === temp){
                arr[i] = e
            }
            else {
                arr[i] = {}
            }
        });
    }
    return (
        <>
            <div className="row miordr2">
                <div className="col-md-12">
                    <div className="table-responsive misftsch">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                                <tr>
                                    <th>Scheduled Shift</th>
                                    {
                                        weekdate.map((current, i) =>{
                                            return (
                                                <th key={i}>{current.day} {current.date}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
                                            <NavLink to="/profile">{auth.user.firstName} {auth.user.lastName} <span>{auth.user.jobTitle}</span></NavLink>
                                        </h2>
                                    </td>
                                    {
                                        arr.map((current, i) =>{
                                            let shiftType;
                                            let shiftTypeCss = "miclsun";
                                            let shiftTypeIcon = "fa fa-sun-o";
                                            let empty = true
                                            let temp;
                                            // let start, end;
                                            let stAmPm = "am";
                                            let enAmPm = "am";
                                            let date;
                                            if (Object.keys(current).length != 0){
                                                let stStart = parseInt(current.startTime.substring(11, 13))
                                                let stStartMin = parseInt(current.startTime.substring(14, 16))
                                                let enEnd = parseInt(current.endTime.substring(11, 13))
                                                let enEndMin = parseInt(current.endTime.substring(14, 16))
                                                if (stStartMin < 10) {
                                                    stStartMin = "0"+stStartMin
                                                }
                                                if (enEndMin < 10){
                                                    enEndMin = "0"+enEndMin
                                                }
                                                if (stStart === 0){
                                                    stStart = 12
                                                }
                                                if (enEnd === 0){
                                                    enEnd = 12
                                                }
                                                if (stStart > 12){
                                                    stStart = stStart - 12
                                                    stAmPm = "pm"
                                                }
                                                if (enEnd > 12){
                                                    enEnd = enEnd - 12
                                                    enAmPm = "pm"
                                                }
                                                if (stStart >= 6 && stAmPm == "am"){
                                                    shiftType = "morning"
                                                    shiftTypeCss="miclsun" 
                                                    shiftTypeIcon = "fa fa-sun-o"
                                                }
                                                else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm"){
                                                    shiftType = "evening"
                                                    shiftTypeCss="miclscl"
                                                    shiftTypeIcon = "fa fa-cloud" 
                                                }
                                                else if (stStart >= 8 && stAmPm == "pm"){
                                                    shiftType = "night"
                                                    shiftTypeCss="miclsmn"
                                                    shiftTypeIcon = "fa fa-moon-o"
                                                }
                                                else if (stStart >= 0 && stAmPm == "am"){
                                                    shiftType = "night"
                                                    shiftTypeCss="miclsmn"
                                                    shiftTypeIcon = "fa fa-moon-o"
                                                }
                                                date = stStart + ":" + stStartMin + " " + stAmPm + "-" + enEnd + ":" + enEndMin + " " + enAmPm;
                                            }
                                            
                                            return (
                                                < >
                                                    {
                                                        Object.keys(current).length != 0
                                                            ?    
                                                            <td style={{minWidth: "10%"}} key={i}>
                                                                {/* , height: "4rem" */}
                                                                <div className={`user-add-shedule-list ${shiftTypeCss}`} >
                                                                    <h2>
                                                                        <NavLink to="/location-view">
                                                                            <div >
                                                                                {/* style={{width: "100%", height: "3rem"}} yeh add karo toh class achi hogi */}
                                                                            <span className="username-info " >{date} {current.duration}</span>
                                                                            <span className="userrole-info">{auth.user.jobTitle} - {current.location.name} <i className={shiftTypeIcon}></i></span>
                                                                            </div>
                                                                        </NavLink>
                                                                    </h2>
                                                                </div>
                                                            </td>
                                                            :
                                                            <td style={{minWidth: "10%"}} key={i}>
                                                                <div className="user-add-shedule-list">
                                                                <h2>
                                                                    <NavLink to="/location-view">
                                                                        
                                                                    </NavLink>
                                                                </h2>
                                                            </div>
                                                        </td> 
                                                    }    
                                                </>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeScheduleCalender


// return (
//     <>
//     {/* {
//         viewCalender === "weekly" ?  */}
//         {/* <EmployeeScheduleViewBar /> */}
//         <div>                              
//                     {/* <div className="row mitblrow">
//                         <div className="col-md-4">
//                             <div className="float-left">
//                                 <button 
//                                 onClick={handleDaily}
//                                 // to="/daily-scheduling" 
//                                 className="btn">Daily</button>
//                                 <button 
//                                 onClick={handleWeek}
//                                 // to="/daily-scheduling" 
//                                 className="btn">Weekly</button>
//                                 <button 
//                                 onClick={handleMonthly}
//                                 // to="/daily-scheduling" 
//                                 className="btn">Monthly</button>
//                                 // <NavLink to="/shift-scheduling" className="btn btn-primary">Weekly</NavLink>
//                                //  <NavLink to="/monthly-scheduling" className="btn">Monthly</NavLink> 
//                             </div>
//                         </div> 
//                         <div className="col-md-4">
//                             <h3 className="card-title mt-2 text-center">27 August to 5 September, 2021</h3>
//                         </div>

//                         <div className="col-md-4">
//                             <div className="float-right">
//                                 <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
//                                 <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>

//                             </div>
//                         </div>
//                     </div> */}
//                     <div className="row miordr2">
//                         <div className="col-md-12">
//                             <div className="table-responsive misftsch">
//                                 <table className="table table-striped custom-table datatable">
//                                     <thead>
//                                         <tr>
//                                             <th>Scheduled Shift</th>
//                                             <th>Fri 21</th>
//                                             <th>Sat 22</th>
//                                             <th>Sun 23</th>
//                                             <th>Mon 24</th>
//                                             <th>Tue 25</th>
//                                             <th>Wed 26</th>
//                                             <th>Thu 27</th>
//                                             <th>Fri 28</th>
//                                             <th>Sat 29</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                                     <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclsun">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-sun-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclsmn">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>

//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West</span>
//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             <td>
//                                             </td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-09.jpg" /></NavLink>
//                                                     <NavLink to="/profile">Richard Miles <span>Armed Officer</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West</span>
//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West</span>
//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-10.jpg" /></NavLink>
//                                                     <NavLink to="/profile">John Smith <span>Officer</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West</span>
//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West</span>
//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-05.jpg" /></NavLink>
//                                                     <NavLink to="/profile">Mike Litorus <span>Officer</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclsmn">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-11.jpg" /></NavLink>
//                                                     <NavLink to="/profile">Wilmer Deluna <span>Team Supervisior</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">
//                                                     <div className="user-add-shedule-list miclsmn">
//                                                         <h2>
//                                                             <NavLink to="/location-view">
//                                                                 <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                                 <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                             </NavLink>
//                                                         </h2>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-12.jpg" /></NavLink>
//                                                     <NavLink to="/profile">Jeffrey Warden <span>Armed Officer</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl miclscltrn">
//                                                     <h2>
//                                                         <NavLink to="#">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 6 hrs)</span>
//                                                             <span className="userrole-info">Gun taining - Head Office</span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl miclscllev">
//                                                     <h2>
//                                                         <NavLink to="#">
//                                                             <span className="username-info ">Casual</span>
//                                                             <span className="userrole-info">Leave</span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclscl">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td>
//                                                 <h2 className="table-avatar">
//                                                     <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-13.jpg" /></NavLink>
//                                                     <NavLink to="/profile">Bernardo Galaviz <span>Armed Officer</span></NavLink>
//                                                 </h2>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclsmn">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list miclsmn">
//                                                     <h2>
//                                                         <NavLink to="/location-view">
//                                                             <span className="username-info ">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
//                                                             <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                                         </NavLink>
//                                                     </h2>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="user-add-shedule-list">

//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                </div>
//     {/* : viewCalender === "daily" ? <EmployeeScheduleDaily /> :
//     <div>ok</div>
//     }  */}
//     </>
// )