import {
    SALARY_REQUEST,
    SALARY_SUCCESS,
    SALARY_FAIL,
} from "../constants"

export const salaryReducer = (
    state = {
        salary: {
            salary: null
        }
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case SALARY_REQUEST : 
            return {
                ...state,
            };
        case SALARY_SUCCESS :
            return {
                ...state,
                salary: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case SALARY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}