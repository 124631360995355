import React, { useState, useEffect } from 'react'
import avatar2 from "../../../assets/img/avatar-02.jpg"
import avatar4 from "../../../assets/img/avatar-04.jpg"
import avatar8 from "../../../assets/img/avatar-08.jpg"
import avatar5 from "../../../assets/img/avatar-05.jpg"
import avatar7 from "../../../assets/img/avatar-07.jpg"
import { NavLink, useNavigate } from 'react-router-dom'
import { shiftPageData, shiftDashboard } from "../../../actions/shiftActions"
import { getCompanyLocations } from "../../../actions/locationActions"
import { companyHolidays } from "../../../actions/holidayActions"
import { clockIn, clockUpdate, clockExist } from "../../../actions/clockActions"
import { employmentAnnivarsary } from "../../../actions/userActions"
import { getMailByUserId } from "../../../actions/mailActions"
import { getUserAnnualStatusByUserId } from "../../../actions/leaveActions"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';

const EmployeeMain = () => {
    const auth = useSelector((state) => state.auth);
    const shift = useSelector((state) => state.shift);
    const clock = useSelector((state) => state.clock);
    const holiday = useSelector((state) => state.holiday);
    const user = useSelector((state) => state.user);
    const leave = useSelector((state) => state.leave);
    let remaining = 0;
    let taken = 0
    let daysLeaveTotal = 0
    const [ clockOutReq, setClockOutReq ] = useState(false)
    const [ clockInReq, setClockInReq ] = useState(false)
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null,
      });
      const [coordinatesClockOut, setCoordinatesClockOut] = useState({
        lat: null,
        lng: null,
      });
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const today = new Date("2022-07-29");
    const dt = today.toISOString().substring(0, 10);
    const clockDate= new Date()
    const shiftData = async (e) => {
        dispatch(shiftPageData({userId: auth.user.id, date: dt}))
        dispatch(shiftDashboard({userId: auth.user.id, date: "2022-07-25"}))
    }
    const locationData = async (e) => {
        dispatch(getCompanyLocations({companyId: auth.user.companyId}))
    }
    const holidayData = async (e) => {
        const currDate = moment().format()
        const arr = currDate.split("+")
        const newCurrDate = arr[0]+".000+00:00"
        dispatch(companyHolidays({companyId: auth.user.companyId, date: newCurrDate}))
    }
    const checkClock = async () => {
        const dd = new Date()
        let month = dd.getMonth()+1
        if ( month < 10){
            month = "0"+month
        }
        const todayRegex = dd.getFullYear()+"-"+month+"-"+dd.getDate()
        const iden = "newDate"+todayRegex+"user"+auth.user.id;
        dispatch(clockExist({dayIdentifier: iden}))
    }
    const annivarsaryData = async (e) => {
        const currDate = moment("2022-07-28").format()
        const arr = currDate.split("T")
        const newCurrDate = arr[0]
        await dispatch(employmentAnnivarsary({companyId: auth.user.companyId, date: newCurrDate}))
        console.log("first")
    }
    const mailData = async () => {
        dispatch(getMailByUserId({userId: auth.user.id}))
    }
    const annualLeaveStatusData = async (e) => {
        dispatch(getUserAnnualStatusByUserId({userId: auth.user.id, date:  moment().format()}))
    }
    useEffect(() => {
        shiftData()
        locationData()
        holidayData()
        checkClock()
        annivarsaryData()
        mailData()
        annualLeaveStatusData()
    },[])
    // if (auth.tokens === null) {
    //   navigate('/login')
    // }
    const clockInUser = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition((position) => {
                setCoordinates({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            });
        }
        setClockInReq(true)
    }
    const clockOutUser = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition((position) => {
                setCoordinatesClockOut({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            });
        }
        setClockOutReq(true)
    }
    const clockOutButton = async () => {
        const currDate = moment().format()
        const arr = currDate.split("+")
        const newCurrDate = arr[0]+".000+00:00"
        dispatch(clockUpdate({clockId: clock.clockData.id, clockOut: newCurrDate,
            longitude: coordinatesClockOut.lng, latitude: coordinatesClockOut.lat}))
    }
    useEffect(() => {
        if (coordinatesClockOut.lat !== null && coordinatesClockOut.lng !== null)
        {
            clockOutButton()
            setClockOutReq(false)
        }
    }, [coordinatesClockOut.lat])
// }, [coordinatesClockOut.lat, coordinatesClockOut.lng, clockOutReq === true])
   
    const clockInButton = async (e) => {
        const currDate = moment().format()
        const arr = currDate.split("+")
        const newCurrDate = arr[0]+".000+00:00"
        dispatch(clockIn({userId: auth.user.id, companyId: auth.user.companyId,
            // date: clockDate.toLocaleString(), longitude: coordinates.lng, latitude: coordinates.lat}))
            date: newCurrDate, longitude: coordinates.lng, latitude: coordinates.lat}))
    }
    const clockInUpdate = async (e) => {
        const currDate = moment().format()
        const arr = currDate.split("+")
        const newCurrDate = arr[0]+".000+00:00"
        dispatch(clockUpdate({clockId: clock.clockData.id, clockIn: newCurrDate,
            longitude: coordinates.lng, latitude: coordinates.lat}))
    }
    useEffect(() => {
        if (coordinates.lat !== null && coordinates.lng !== null)
        {   
            if (clock.clockData !== null ){
                clockInUpdate()
                setClockInReq(false)
            }
            else {
                clockInButton()
                setClockInReq(false)
            }
        }
    },[coordinates.lat])
// },[coordinates.lat, coordinates.lng, clockInReq === true])
    console.log(coordinates)
    // console.log(coordinatesClockOut)
    const currentTimeadmin = () => {
        let hours = new Date().getHours()
        let amPm = "am"
        if (hours == 0){
            hours = 12
        }
        if ( hours > 12){
            hours = hours - 12
            amPm = "pm"
        }
        let mints = new Date().getMinutes()
        // console.log(mints)
        if (mints <10){
            mints = "0"+mints
            // console.log(mints)
        }
        const CurrDate = hours + ":" + mints + " " + amPm
        return CurrDate
    }


    const [ timer, setTime ] = useState(currentTimeadmin());

    useEffect(() => {
        const intervel = setInterval(() => setTime(currentTimeadmin()), 1000)
        return () => clearInterval(intervel)             
    },[])

    
    let daysArray = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]
    let monthArray = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ]

    const firstName = auth.user.firstName;

    const lastName = auth.user.lastName;    

    let day = new Date().getDay();
    let month = new Date().getMonth();

    let dayName = daysArray[day];
    let monthName = monthArray[month];
    const currentTime = new Date()
    const dateToday = `${dayName}, ${currentTime.getDate()} ${monthName} ${currentTime.getFullYear()}`
    return (
        <>
            (<div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="welcome-box">
                                    <div className="welcome-img">
                                        <img alt="" src={avatar2} />
                                    </div>
                                    <div className="welcome-det">
                                        {/* ***** */}
                                        <h3>Welcome, {firstName} {lastName}</h3>
                                        {/* <h3>{firstName}</h3>
                                        <h3>{lastName}</h3> */}
                                       
                                        <p>{dateToday}</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <section className="dash-section">
                                    <h1 className="dash-sec-title">Today</h1>
                                    <div className="dash-sec-content">
                                        <div className="dash-info-list">
                                            <a className="dash-card">
                                                <div className="dash-card-container">
                                                    <div className="dash-card-icon">
                                                        <i className="fa fa-suitcase"></i>
                                                    </div>
                                                    <div className="dash-card-content">
                                                        {/* <p>Time Clock <span className="text-success">{dateToday}<p><p>{timer}</p></p></span></p> */}
                                                        <p>Time Clock <span className="text-success">{dateToday} <br/> {timer}</span></p>
                                                    </div>
                                                    <div className="dash-card-avatars">
                                                        <div className="request-btn midshbtn">
                                                            {   clock.clockInButton === true &&
                                                                <p className="btn btn-primary" onClick={clockInUser}>Clock-In</p>
                                                            }
                                                            {   clock.clockInButton === false &&
                                                                <p className="btn btn-primary" onClick={clockOutUser}>Clock Out</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section className="dash-section">
                                    { shift.tomorrowShift !== null &&
                                        <>
                                            <h1 className="dash-sec-title">Tomorrow</h1>
                                            <div className="dash-sec-content">
                                                <div className="dash-info-list">
                                                    <div className="dash-card">
                                                        <div className="dash-card-container">
                                                            <div className="dash-card-icon">
                                                                <i className="fa fa-suitcase"></i>
                                                            </div>
                                                            <div className="dash-card-content miminctbx">
                                                                <p>You Will be working as un-armed officer</p>
                                                                <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
                                                            </div>
                                                            <div className="dash-card-avatars miminbtbx">
                                                                <NavLink to="#" className="e-avatar"><img src={avatar4} alt="" /></NavLink>
                                                                <NavLink to="#" className="e-avatar"><img src={avatar8} alt="" /></NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </section>
                                <section className="dash-section">
                                    {
                                        shift.thisWeek &&
                                            shift.thisWeek.length > 0 &&
                                                <>
                                                    <h1 className="dash-sec-title">Next seven days</h1>
                                                    <div className="dash-sec-content">
                                                    {   shift.thisWeek !== null &&
                                                            shift.thisWeek.map((current, i) => {
                                                                return(
                                                                    <>
                                                                        <div className="dash-info-list">
                                                                            <div className="dash-card">
                                                                                <div className="dash-card-container">
                                                                                    <div className="dash-card-icon">
                                                                                        <i className="fa fa-suitcase"></i>
                                                                                    </div>
                                                                                    <div className="dash-card-content miminctbx">
                                                                                        <p>You Will be working as {auth.user.jobTitle}</p>
                                                                                        <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> On {current.startTime.substring(5, 10)}, {current.startTime.substring(11, 16)} - {current.endTime.substring(11, 16)}, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
                                                                                    </div>
                                                                                    <div className="dash-card-avatars miminbtbx">
                                                                                        <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
                                                                                        <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                    }
                                                        
                                                        {/* <div className="dash-info-list">
                                                            <div className="dash-card">
                                                                <div className="dash-card-container">
                                                                    <div className="dash-card-icon">
                                                                        <i className="fa fa-suitcase"></i>
                                                                    </div>
                                                                    <div className="dash-card-content miminctbx">
                                                                        <p>You Will be working as un-armed officer</p>
                                                                        <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
                                                                    </div>
                                                                    <div className="dash-card-avatars miminbtbx">
                                                                        <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
                                                                        <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </>
                                                
                                    }
                                    </section>
                                    <section>
                                        {   holiday.holiday &&
                                            holiday.holiday.length > 0 &&
                                            <>
                                                <div className="dash-sec-content">
                                                    <h1 className="dash-sec-title">Holidays</h1>
                                                        {
                                                            holiday.holiday.map((current,i) => {
                                                                return(
                                                                    <div className="dash-info-list" key={i}>
                                                                        <NavLink to="" className="dash-card">
                                                                            <div className="dash-card-container">
                                                                                <div className="dash-card-icon">
                                                                                    <i className="fa fa-calendar"></i>
                                                                                </div>
                                                                                <div className="dash-card-content">
                                                                                    <p>It's {current.holidayName} Holiday on {current.day}</p>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                </div>
                                            </>
                                        }
                                </section>
                                <br/>
                                {   user.annivarsary &&
                                    user.annivarsary.length > 0 &&
                                        <section className="dash-section">
                                            <h1 className="dash-sec-title">Annual Anniversaries</h1>
                                            {
                                                user.annivarsary.map((current,i) => {
                                                    return(
                                                        current.length > 0 &&
                                                            <div className="dash-sec-content" key={i}>
                                                                <div className="dash-info-list">
                                                                    {
                                                                        current.map((e, index)=> {
                                                                            console.log(e)
                                                                            return(
                                                                                <div className="dash-card" key={index}>
                                                                                    <div className="dash-card-container">
                                                                                        <div className="dash-card-icon">
                                                                                            <i className="fa fa-suitcase"></i>
                                                                                        </div>
                                                                                        <div className="dash-card-content miminctbx">
                                                                                            <p>{e.firstName}, {e.lastName}</p>
                                                                                            <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span>
                                                                                            { i === 0 ? " Today" : i === 1 ? " Tomorrow" : " In " +(i+1)+ " days" } <span><i className="fa fa-hourglass-2"></i></span> <NavLink to="/location-view"> { parseInt(e.startingDate.substring(0,4)) === parseInt(moment().format().substring(0,4)) ? "Joined Recently": (parseInt(moment().format().substring(0,4)) - parseInt(e.startingDate.substring(0,4))) + " Year"}</NavLink></div>
                                                                                        </div>
                                                                                        <div className="dash-card-avatars miminbtbx mianibx">
                                                                                            <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>  
                                                    )
                                                })
                                            }
                                        </section>
                                }    
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="dash-sidebar">
                                    <section>
                                        <h5 className="dash-title">Locations</h5>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="time-list">
                                                    <div className="dash-stats-list">
                                                        <h4>{shift.activeLocationThisWeek}</h4>
                                                        {/* <h4>71</h4> */}
                                                        <p>Active Locations</p>
                                                        <p>Next Seven Days</p>
                                                        {/* <p>This Week</p> */}
                                                        {/* <p>Previous Locations</p> */}
                                                    </div>
                                                    <div className="dash-stats-list">
                                                        <h4>{shift.activeLocationThisMonth}</h4>
                                                        {/* <h4>14</h4> */}
                                                        <p>Active Locations</p>
                                                        <p>This Month</p>
                                                        {/* <p>Upcoming Locations</p> */}
                                                    </div>
                                                </div>
                                                {/* <div className="request-btn">
                                                    <div className="dash-stats-list">
                                                        <h4>2</h4>
                                                        <p>Active Locations</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </section>
                                    <section>
                                        <h5 className="dash-title">Your Leave</h5>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="time-list">
                                                    <div className="dash-stats-list">
                                                        {   leave.leaveAnnualStatus &&
                                                                leave.leaveAnnualStatus.length > 0 &&
                                                                    leave.leaveAnnualStatus.forEach(current => {
                                                                        remaining += current.remainingLeavesDays
                                                                        daysLeaveTotal += current.annualLeavesDays
                                                                    })
                                                                                                                                    
                                                        }
                                                        {   leave.leaveAnnualStatus &&
                                                                leave.leaveAnnualStatus.length > 0 &&
                                                                    <h4>{taken= daysLeaveTotal - remaining}</h4>
                                                        }
                                                        <p>Leave Taken</p>
                                                    </div>
                                                    <div className="dash-stats-list">
                                                    {   leave.leaveAnnualStatus &&
                                                            leave.leaveAnnualStatus.length > 0 &&
                                                                <h4>{remaining}</h4>
                                                    }
                                                        <p>Remaining</p>
                                                    </div>
                                                </div>
                                                <div className="request-btn">
                                                    <NavLink className="btn btn-primary" to="/leaves">Apply Leave</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section>
                                        <h5 className="dash-title">Shared Files</h5>
                                        <div className="file-wrap midsfl">
                                            <div className="file-cont-wrap">
                                                <div className="file-cont-inner">
                                                    <div className="file-content">
                                                        <div className="file-body">
                                                            <div className="file-scroll">
                                                                <div className="file-content-inner">
                                                                    <div className="row row-sm">
                                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                            <div className="card card-file">
                                                                                <div className="dropdown-file">
                                                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-file-thumb">
                                                                                    <i className="fa fa-file-pdf-o"></i>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <h6><NavLink to="">Sample.pdf</NavLink></h6>
                                                                                    <span>10.45kb</span>
                                                                                </div>
                                                                                <div className="card-footer">
                                                                                    <span className="d-none d-sm-inline">Last Modified: </span>1 min ago
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                            <div className="card card-file">
                                                                                <div className="dropdown-file">
                                                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-file-thumb">
                                                                                    <i className="fa fa-file-word-o"></i>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <h6><NavLink to="">Document.docx</NavLink></h6>
                                                                                    <span>22.67kb</span>
                                                                                </div>
                                                                                <div className="card-footer">
                                                                                    <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="request-btn">
                                                                        <NavLink className="btn mx-auto btn-primary" to="/file-manager">View All</NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
         {/* } */}
    </>
    
    )
}

export default EmployeeMain



// import React, { useState, useEffect } from 'react'
// import avatar2 from "../../../assets/img/avatar-02.jpg"
// import avatar4 from "../../../assets/img/avatar-04.jpg"
// import avatar8 from "../../../assets/img/avatar-08.jpg"
// import avatar5 from "../../../assets/img/avatar-05.jpg"
// import avatar7 from "../../../assets/img/avatar-07.jpg"
// import { NavLink } from 'react-router-dom'
// // added *****
// import { useSelector } from 'react-redux'
// import { userSelector } from '../../../app/features/loginSlice'

// const EmployeeMain = () => {
//     const { userToken,userById } = useSelector(
//         userSelector    
//     )
//     const currentTimeadmin = () => {
//         let hours = new Date().getHours()
//         let amPm = "am"
//         if (hours == 0){
//             hours = 12
//         }
//         if ( hours > 12){
//             hours = hours - 12
//             amPm = "pm"
//         }
//         let mints = new Date().getMinutes()
//         console.log(mints)
//         if (mints <10){
//             mints = "0"+mints
//             console.log(mints)
//         }
//         const CurrDate = hours + ":" + mints + " " + amPm
//         return CurrDate
//     }


//     const [ timer, setTime ] = useState(currentTimeadmin());

//     useEffect(() => {
//         const intervel = setInterval(() => setTime(currentTimeadmin()), 1000)
//         return () => clearInterval(intervel)             
//     },[])

    
//     let daysArray = [
//         "Sunday",
//         "Monday",
//         "Tuesday",
//         "Wednesday",
//         "Thursday",
//         "Friday",
//         "Saturday"
//     ]
//     let monthArray = [
//         "January", "February", "March", "April", "May", "June", "July",
//         "August", "September", "October", "November", "December"
//     ]
//     // const userName = localStorage.getItem("name") ? 
//     // localStorage.getItem('name') : <p>null</p>
//     // const userName = localStorage.getItem("name") ? 
//     // localStorage.getItem('name') : <p>null</p>
//     const firstName = (localStorage.getItem("firstName")) ? 
//     (localStorage.getItem('firstName')) : <p>null</p>

//     const lastName = (localStorage.getItem("lastName")) ? 
//     (localStorage.getItem('lastName')) : <p>null</p>
    

//     let day = new Date().getDay();
//     let month = new Date().getMonth();

//     let dayName = daysArray[day];
//     let monthName = monthArray[month];
//     const currentTime = new Date()
//     const dateToday = `${dayName}, ${currentTime.getDate()} ${monthName} ${currentTime.getFullYear()}`
//     return (
//         <>
//         {
//         userToken &&
//         // *****
//             (<div className="main-wrapper">
//                 <div className="page-wrapper">
//                     <div className="content container-fluid">
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <div className="welcome-box">
//                                     <div className="welcome-img">
//                                         <img alt="" src={avatar2} />
//                                     </div>
//                                     <div className="welcome-det">
//                                         {/* ***** */}
//                                         <h3>Welcome, {firstName} {lastName}</h3>
//                                         {/* <h3>{firstName}</h3>
//                                         <h3>{lastName}</h3> */}
                                       
//                                         <p>{dateToday}</p>
                                        
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="col-lg-8 col-md-8">
//                                 <section className="dash-section">
//                                     <h1 className="dash-sec-title">Today</h1>
//                                     <div className="dash-sec-content">
//                                         <div className="dash-info-list">
//                                             <NavLink to="/attendance" className="dash-card">
//                                                 <div className="dash-card-container">
//                                                     <div className="dash-card-icon">
//                                                         <i className="fa fa-suitcase"></i>
//                                                     </div>
//                                                     <div className="dash-card-content">
//                                                         <p>Time Clock <span className="text-success">{dateToday}<p><p>{timer}</p></p></span></p>
//                                                     </div>
//                                                     <div className="dash-card-avatars">
//                                                         <div className="request-btn midshbtn">
//                                                             <p className="btn btn-primary">Clock-In</p>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </NavLink>
//                                         </div>
//                                     </div>
//                                 </section>
//                                 <section className="dash-section">
//                                     <h1 className="dash-sec-title">Tomorrow</h1>
//                                     <div className="dash-sec-content">
//                                         <div className="dash-info-list">
//                                             <div className="dash-card">
//                                                 <div className="dash-card-container">
//                                                     <div className="dash-card-icon">
//                                                         <i className="fa fa-suitcase"></i>
//                                                     </div>
//                                                     <div className="dash-card-content miminctbx">
//                                                         <p>You Will be working as un-armed officer</p>
//                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
//                                                     </div>
//                                                     <div className="dash-card-avatars miminbtbx">
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar4} alt="" /></NavLink>
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar8} alt="" /></NavLink>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </section>
//                                 <section className="dash-section">
//                                     <h1 className="dash-sec-title">Next seven days</h1>
//                                     <div className="dash-sec-content">
//                                         <div className="dash-info-list">
//                                             <div className="dash-card">
//                                                 <div className="dash-card-container">
//                                                     <div className="dash-card-icon">
//                                                         <i className="fa fa-suitcase"></i>
//                                                     </div>
//                                                     <div className="dash-card-content miminctbx">
//                                                         <p>You Will be working as un-armed officer</p>
//                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
//                                                     </div>
//                                                     <div className="dash-card-avatars miminbtbx">
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="dash-info-list">
//                                             <div className="dash-card">
//                                                 <div className="dash-card-container">
//                                                     <div className="dash-card-icon">
//                                                         <i className="fa fa-suitcase"></i>
//                                                     </div>
//                                                     <div className="dash-card-content miminctbx">
//                                                         <p>You Will be working as un-armed officer</p>
//                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
//                                                     </div>
//                                                     <div className="dash-card-avatars miminbtbx">
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="dash-info-list">
//                                             <NavLink to="" className="dash-card">
//                                                 <div className="dash-card-container">
//                                                     <div className="dash-card-icon">
//                                                         <i className="fa fa-calendar"></i>
//                                                     </div>
//                                                     <div className="dash-card-content">
//                                                         <p>It's Spring Bank Holiday on Monday</p>
//                                                     </div>
//                                                 </div>
//                                             </NavLink>
//                                         </div>
//                                     </div>
//                                 </section>
//                                 <section className="dash-section">
//                                     <h1 className="dash-sec-title">Annual Anniversaries</h1>
//                                     <div className="dash-sec-content">
//                                         <div className="dash-info-list">
//                                             <div className="dash-card">
//                                                 <div className="dash-card-container">
//                                                     <div className="dash-card-icon">
//                                                         <i className="fa fa-suitcase"></i>
//                                                     </div>
//                                                     <div className="dash-card-content miminctbx">
//                                                         <p>Ali, Abdirahman</p>
//                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> In 4 days <span><i className="fa fa-hourglass-2"></i></span> <NavLink to="/location-view"> 1 year</NavLink></div>
//                                                     </div>
//                                                     <div className="dash-card-avatars miminbtbx mianibx">
//                                                         <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </section>
//                             </div>
//                             <div className="col-lg-4 col-md-4">
//                                 <div className="dash-sidebar">
//                                     <section>
//                                         <h5 className="dash-title">Locations</h5>
//                                         <div className="card">
//                                             <div className="card-body">
//                                                 <div className="time-list">
//                                                     <div className="dash-stats-list">
//                                                         <h4>71</h4>
//                                                         <p>Previous Locations</p>
//                                                     </div>
//                                                     <div className="dash-stats-list">
//                                                         <h4>14</h4>
//                                                         <p>Upcoming Locations</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="request-btn">
//                                                     <div className="dash-stats-list">
//                                                         <h4>2</h4>
//                                                         <p>Active Locations</p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </section>
//                                     <section>
//                                         <h5 className="dash-title">Your Leave</h5>
//                                         <div className="card">
//                                             <div className="card-body">
//                                                 <div className="time-list">
//                                                     <div className="dash-stats-list">
//                                                         <h4>4</h4>
//                                                         <p>Leave Taken</p>
//                                                     </div>
//                                                     <div className="dash-stats-list">
//                                                         <h4>12</h4>
//                                                         <p>Remaining</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="request-btn">
//                                                     <NavLink className="btn btn-primary" to="/leaves">Apply Leave</NavLink>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </section>
//                                     <section>
//                                         <h5 className="dash-title">Shared Files</h5>
//                                         <div className="file-wrap midsfl">
//                                             <div className="file-cont-wrap">
//                                                 <div className="file-cont-inner">
//                                                     <div className="file-content">
//                                                         <div className="file-body">
//                                                             <div className="file-scroll">
//                                                                 <div className="file-content-inner">
//                                                                     <div className="row row-sm">
//                                                                         <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
//                                                                             <div className="card card-file">
//                                                                                 <div className="dropdown-file">
//                                                                                     <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
//                                                                                     <div className="dropdown-menu dropdown-menu-right">
//                                                                                         <NavLink to="#" className="dropdown-item">View Details</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Share</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Download</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Rename</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Delete</NavLink>
//                                                                                     </div>
//                                                                                 </div>
//                                                                                 <div className="card-file-thumb">
//                                                                                     <i className="fa fa-file-pdf-o"></i>
//                                                                                 </div>
//                                                                                 <div className="card-body">
//                                                                                     <h6><NavLink to="">Sample.pdf</NavLink></h6>
//                                                                                     <span>10.45kb</span>
//                                                                                 </div>
//                                                                                 <div className="card-footer">
//                                                                                     <span className="d-none d-sm-inline">Last Modified: </span>1 min ago
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
//                                                                             <div className="card card-file">
//                                                                                 <div className="dropdown-file">
//                                                                                     <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
//                                                                                     <div className="dropdown-menu dropdown-menu-right">
//                                                                                         <NavLink to="#" className="dropdown-item">View Details</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Share</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Download</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Rename</NavLink>
//                                                                                         <NavLink to="#" className="dropdown-item">Delete</NavLink>
//                                                                                     </div>
//                                                                                 </div>
//                                                                                 <div className="card-file-thumb">
//                                                                                     <i className="fa fa-file-word-o"></i>
//                                                                                 </div>
//                                                                                 <div className="card-body">
//                                                                                     <h6><NavLink to="">Document.docx</NavLink></h6>
//                                                                                     <span>22.67kb</span>
//                                                                                 </div>
//                                                                                 <div className="card-footer">
//                                                                                     <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>

//                                                                     </div>
//                                                                     <div className="request-btn">
//                                                                         <NavLink className="btn mx-auto btn-primary" to="/file-manager">View All</NavLink>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </section>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             )
//          }
//     </>
    
//     )
// }

// export default EmployeeMain















// return (
//     <>
//     {/* {
//     userToken && */}
//     // *****
//         (<div className="main-wrapper">
//             <div className="page-wrapper">
//                 <div className="content container-fluid">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="welcome-box">
//                                 <div className="welcome-img">
//                                     <img alt="" src={avatar2} />
//                                 </div>
//                                 <div className="welcome-det">
//                                     {/* ***** */}
//                                     <h3>Welcome, {firstName} {lastName}</h3>
//                                     {/* <h3>{firstName}</h3>
//                                     <h3>{lastName}</h3> */}
                                   
//                                     <p>{dateToday}</p>
                                    
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-lg-8 col-md-8">
//                             <section className="dash-section">
//                                 <h1 className="dash-sec-title">Today</h1>
//                                 <div className="dash-sec-content">
//                                     <div className="dash-info-list">
//                                         <NavLink to="/attendance" className="dash-card">
//                                             <div className="dash-card-container">
//                                                 <div className="dash-card-icon">
//                                                     <i className="fa fa-suitcase"></i>
//                                                 </div>
//                                                 <div className="dash-card-content">
//                                                     {/* <p>Time Clock <span className="text-success">{dateToday}<p><p>{timer}</p></p></span></p> */}
//                                                     <p>Time Clock <span className="text-success">{dateToday} <br/> {timer}</span></p>
//                                                 </div>
//                                                 <div className="dash-card-avatars">
//                                                     <div className="request-btn midshbtn">
//                                                         <p className="btn btn-primary">Clock-In</p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </NavLink>
//                                     </div>
//                                 </div>
//                             </section>
//                             <section className="dash-section">
//                                 <h1 className="dash-sec-title">Tomorrow</h1>
//                                 <div className="dash-sec-content">
//                                     <div className="dash-info-list">
//                                         <div className="dash-card">
//                                             <div className="dash-card-container">
//                                                 <div className="dash-card-icon">
//                                                     <i className="fa fa-suitcase"></i>
//                                                 </div>
//                                                 <div className="dash-card-content miminctbx">
//                                                     <p>You Will be working as un-armed officer</p>
//                                                     <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
//                                                 </div>
//                                                 <div className="dash-card-avatars miminbtbx">
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar4} alt="" /></NavLink>
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar8} alt="" /></NavLink>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </section>
//                             <section className="dash-section">
//                                 <h1 className="dash-sec-title">Next seven days</h1>
//                                 <div className="dash-sec-content">
//                                     <div className="dash-info-list">
//                                         <div className="dash-card">
//                                             <div className="dash-card-container">
//                                                 <div className="dash-card-icon">
//                                                     <i className="fa fa-suitcase"></i>
//                                                 </div>
//                                                 <div className="dash-card-content miminctbx">
//                                                     <p>You Will be working as un-armed officer</p>
//                                                     <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
//                                                 </div>
//                                                 <div className="dash-card-avatars miminbtbx">
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="dash-info-list">
//                                         <div className="dash-card">
//                                             <div className="dash-card-container">
//                                                 <div className="dash-card-icon">
//                                                     <i className="fa fa-suitcase"></i>
//                                                 </div>
//                                                 <div className="dash-card-content miminctbx">
//                                                     <p>You Will be working as un-armed officer</p>
//                                                     <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
//                                                 </div>
//                                                 <div className="dash-card-avatars miminbtbx">
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="dash-info-list">
//                                         <NavLink to="" className="dash-card">
//                                             <div className="dash-card-container">
//                                                 <div className="dash-card-icon">
//                                                     <i className="fa fa-calendar"></i>
//                                                 </div>
//                                                 <div className="dash-card-content">
//                                                     <p>It's Spring Bank Holiday on Monday</p>
//                                                 </div>
//                                             </div>
//                                         </NavLink>
//                                     </div>
//                                 </div>
//                             </section>
//                             <section className="dash-section">
//                                 <h1 className="dash-sec-title">Annual Anniversaries</h1>
//                                 <div className="dash-sec-content">
//                                     <div className="dash-info-list">
//                                         <div className="dash-card">
//                                             <div className="dash-card-container">
//                                                 <div className="dash-card-icon">
//                                                     <i className="fa fa-suitcase"></i>
//                                                 </div>
//                                                 <div className="dash-card-content miminctbx">
//                                                     <p>Ali, Abdirahman</p>
//                                                     <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> In 4 days <span><i className="fa fa-hourglass-2"></i></span> <NavLink to="/location-view"> 1 year</NavLink></div>
//                                                 </div>
//                                                 <div className="dash-card-avatars miminbtbx mianibx">
//                                                     <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </section>
//                         </div>
//                         <div className="col-lg-4 col-md-4">
//                             <div className="dash-sidebar">
//                                 <section>
//                                     <h5 className="dash-title">Locations</h5>
//                                     <div className="card">
//                                         <div className="card-body">
//                                             <div className="time-list">
//                                                 <div className="dash-stats-list">
//                                                     <h4>{shift.activeLocationThisWeek}</h4>
//                                                     {/* <h4>71</h4> */}
//                                                     <p>Active Locations</p>
//                                                     <p>This Week</p>
//                                                     {/* <p>Previous Locations</p> */}
//                                                 </div>
//                                                 <div className="dash-stats-list">
//                                                     <h4>{shift.activeLocationThisMonth}</h4>
//                                                     {/* <h4>14</h4> */}
//                                                     <p>Active Locations</p>
//                                                     <p>This Month</p>
//                                                     {/* <p>Upcoming Locations</p> */}
//                                                 </div>
//                                             </div>
//                                             {/* <div className="request-btn">
//                                                 <div className="dash-stats-list">
//                                                     <h4>2</h4>
//                                                     <p>Active Locations</p>
//                                                 </div>
//                                             </div> */}
//                                         </div>
//                                     </div>
//                                 </section>
//                                 <section>
//                                     <h5 className="dash-title">Your Leave</h5>
//                                     <div className="card">
//                                         <div className="card-body">
//                                             <div className="time-list">
//                                                 <div className="dash-stats-list">
//                                                     <h4>4</h4>
//                                                     <p>Leave Taken</p>
//                                                 </div>
//                                                 <div className="dash-stats-list">
//                                                     <h4>12</h4>
//                                                     <p>Remaining</p>
//                                                 </div>
//                                             </div>
//                                             <div className="request-btn">
//                                                 <NavLink className="btn btn-primary" to="/leaves">Apply Leave</NavLink>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </section>
//                                 <section>
//                                     <h5 className="dash-title">Shared Files</h5>
//                                     <div className="file-wrap midsfl">
//                                         <div className="file-cont-wrap">
//                                             <div className="file-cont-inner">
//                                                 <div className="file-content">
//                                                     <div className="file-body">
//                                                         <div className="file-scroll">
//                                                             <div className="file-content-inner">
//                                                                 <div className="row row-sm">
//                                                                     <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
//                                                                         <div className="card card-file">
//                                                                             <div className="dropdown-file">
//                                                                                 <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
//                                                                                 <div className="dropdown-menu dropdown-menu-right">
//                                                                                     <NavLink to="#" className="dropdown-item">View Details</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Share</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Download</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Rename</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Delete</NavLink>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className="card-file-thumb">
//                                                                                 <i className="fa fa-file-pdf-o"></i>
//                                                                             </div>
//                                                                             <div className="card-body">
//                                                                                 <h6><NavLink to="">Sample.pdf</NavLink></h6>
//                                                                                 <span>10.45kb</span>
//                                                                             </div>
//                                                                             <div className="card-footer">
//                                                                                 <span className="d-none d-sm-inline">Last Modified: </span>1 min ago
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                     <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
//                                                                         <div className="card card-file">
//                                                                             <div className="dropdown-file">
//                                                                                 <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
//                                                                                 <div className="dropdown-menu dropdown-menu-right">
//                                                                                     <NavLink to="#" className="dropdown-item">View Details</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Share</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Download</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Rename</NavLink>
//                                                                                     <NavLink to="#" className="dropdown-item">Delete</NavLink>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className="card-file-thumb">
//                                                                                 <i className="fa fa-file-word-o"></i>
//                                                                             </div>
//                                                                             <div className="card-body">
//                                                                                 <h6><NavLink to="">Document.docx</NavLink></h6>
//                                                                                 <span>22.67kb</span>
//                                                                             </div>
//                                                                             <div className="card-footer">
//                                                                                 <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>

//                                                                 </div>
//                                                                 <div className="request-btn">
//                                                                     <NavLink className="btn mx-auto btn-primary" to="/file-manager">View All</NavLink>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </section>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         )
//      {/* } */}
// </>

// )
// }

// export default EmployeeMain



// // import React, { useState, useEffect } from 'react'
// // import avatar2 from "../../../assets/img/avatar-02.jpg"
// // import avatar4 from "../../../assets/img/avatar-04.jpg"
// // import avatar8 from "../../../assets/img/avatar-08.jpg"
// // import avatar5 from "../../../assets/img/avatar-05.jpg"
// // import avatar7 from "../../../assets/img/avatar-07.jpg"
// // import { NavLink } from 'react-router-dom'
// // // added *****
// // import { useSelector } from 'react-redux'
// // import { userSelector } from '../../../app/features/loginSlice'

// // const EmployeeMain = () => {
// //     const { userToken,userById } = useSelector(
// //         userSelector    
// //     )
// //     const currentTimeadmin = () => {
// //         let hours = new Date().getHours()
// //         let amPm = "am"
// //         if (hours == 0){
// //             hours = 12
// //         }
// //         if ( hours > 12){
// //             hours = hours - 12
// //             amPm = "pm"
// //         }
// //         let mints = new Date().getMinutes()
// //         console.log(mints)
// //         if (mints <10){
// //             mints = "0"+mints
// //             console.log(mints)
// //         }
// //         const CurrDate = hours + ":" + mints + " " + amPm
// //         return CurrDate
// //     }


// //     const [ timer, setTime ] = useState(currentTimeadmin());

// //     useEffect(() => {
// //         const intervel = setInterval(() => setTime(currentTimeadmin()), 1000)
// //         return () => clearInterval(intervel)             
// //     },[])


// //     let daysArray = [
// //         "Sunday",
// //         "Monday",
// //         "Tuesday",
// //         "Wednesday",
// //         "Thursday",
// //         "Friday",
// //         "Saturday"
// //     ]
// //     let monthArray = [
// //         "January", "February", "March", "April", "May", "June", "July",
// //         "August", "September", "October", "November", "December"
// //     ]
// //     // const userName = localStorage.getItem("name") ? 
// //     // localStorage.getItem('name') : <p>null</p>
// //     // const userName = localStorage.getItem("name") ? 
// //     // localStorage.getItem('name') : <p>null</p>
// //     const firstName = (localStorage.getItem("firstName")) ? 
// //     (localStorage.getItem('firstName')) : <p>null</p>

// //     const lastName = (localStorage.getItem("lastName")) ? 
// //     (localStorage.getItem('lastName')) : <p>null</p>


// //     let day = new Date().getDay();
// //     let month = new Date().getMonth();

// //     let dayName = daysArray[day];
// //     let monthName = monthArray[month];
// //     const currentTime = new Date()
// //     const dateToday = `${dayName}, ${currentTime.getDate()} ${monthName} ${currentTime.getFullYear()}`
// //     return (
// //         <>
// //         {
// //         userToken &&
// //         // *****
// //             (<div className="main-wrapper">
// //                 <div className="page-wrapper">
// //                     <div className="content container-fluid">
// //                         <div className="row">
// //                             <div className="col-md-12">
// //                                 <div className="welcome-box">
// //                                     <div className="welcome-img">
// //                                         <img alt="" src={avatar2} />
// //                                     </div>
// //                                     <div className="welcome-det">
// //                                         {/* ***** */}
// //                                         <h3>Welcome, {firstName} {lastName}</h3>
// //                                         {/* <h3>{firstName}</h3>
// //                                         <h3>{lastName}</h3> */}
                                   
// //                                         <p>{dateToday}</p>
                                    
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div className="row">
// //                             <div className="col-lg-8 col-md-8">
// //                                 <section className="dash-section">
// //                                     <h1 className="dash-sec-title">Today</h1>
// //                                     <div className="dash-sec-content">
// //                                         <div className="dash-info-list">
// //                                             <NavLink to="/attendance" className="dash-card">
// //                                                 <div className="dash-card-container">
// //                                                     <div className="dash-card-icon">
// //                                                         <i className="fa fa-suitcase"></i>
// //                                                     </div>
// //                                                     <div className="dash-card-content">
// //                                                         <p>Time Clock <span className="text-success">{dateToday}<p><p>{timer}</p></p></span></p>
// //                                                     </div>
// //                                                     <div className="dash-card-avatars">
// //                                                         <div className="request-btn midshbtn">
// //                                                             <p className="btn btn-primary">Clock-In</p>
// //                                                         </div>
// //                                                     </div>
// //                                                 </div>
// //                                             </NavLink>
// //                                         </div>
// //                                     </div>
// //                                 </section>
// //                                 <section className="dash-section">
// //                                     <h1 className="dash-sec-title">Tomorrow</h1>
// //                                     <div className="dash-sec-content">
// //                                         <div className="dash-info-list">
// //                                             <div className="dash-card">
// //                                                 <div className="dash-card-container">
// //                                                     <div className="dash-card-icon">
// //                                                         <i className="fa fa-suitcase"></i>
// //                                                     </div>
// //                                                     <div className="dash-card-content miminctbx">
// //                                                         <p>You Will be working as un-armed officer</p>
// //                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
// //                                                     </div>
// //                                                     <div className="dash-card-avatars miminbtbx">
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar4} alt="" /></NavLink>
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar8} alt="" /></NavLink>
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                     </div>
// //                                 </section>
// //                                 <section className="dash-section">
// //                                     <h1 className="dash-sec-title">Next seven days</h1>
// //                                     <div className="dash-sec-content">
// //                                         <div className="dash-info-list">
// //                                             <div className="dash-card">
// //                                                 <div className="dash-card-container">
// //                                                     <div className="dash-card-icon">
// //                                                         <i className="fa fa-suitcase"></i>
// //                                                     </div>
// //                                                     <div className="dash-card-content miminctbx">
// //                                                         <p>You Will be working as un-armed officer</p>
// //                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
// //                                                     </div>
// //                                                     <div className="dash-card-avatars miminbtbx">
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                         <div className="dash-info-list">
// //                                             <div className="dash-card">
// //                                                 <div className="dash-card-container">
// //                                                     <div className="dash-card-icon">
// //                                                         <i className="fa fa-suitcase"></i>
// //                                                     </div>
// //                                                     <div className="dash-card-content miminctbx">
// //                                                         <p>You Will be working as un-armed officer</p>
// //                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> 10: 00-20: 00, <span><i className="fa fa-map-marker"></i></span> <NavLink to="/location-view"> Ripley's Times Square 330256</NavLink></div>
// //                                                     </div>
// //                                                     <div className="dash-card-avatars miminbtbx">
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar7} alt="" /></NavLink>
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                         <div className="dash-info-list">
// //                                             <NavLink to="" className="dash-card">
// //                                                 <div className="dash-card-container">
// //                                                     <div className="dash-card-icon">
// //                                                         <i className="fa fa-calendar"></i>
// //                                                     </div>
// //                                                     <div className="dash-card-content">
// //                                                         <p>It's Spring Bank Holiday on Monday</p>
// //                                                     </div>
// //                                                 </div>
// //                                             </NavLink>
// //                                         </div>
// //                                     </div>
// //                                 </section>
// //                                 <section className="dash-section">
// //                                     <h1 className="dash-sec-title">Annual Anniversaries</h1>
// //                                     <div className="dash-sec-content">
// //                                         <div className="dash-info-list">
// //                                             <div className="dash-card">
// //                                                 <div className="dash-card-container">
// //                                                     <div className="dash-card-icon">
// //                                                         <i className="fa fa-suitcase"></i>
// //                                                     </div>
// //                                                     <div className="dash-card-content miminctbx">
// //                                                         <p>Ali, Abdirahman</p>
// //                                                         <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span> In 4 days <span><i className="fa fa-hourglass-2"></i></span> <NavLink to="/location-view"> 1 year</NavLink></div>
// //                                                     </div>
// //                                                     <div className="dash-card-avatars miminbtbx mianibx">
// //                                                         <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                     </div>
// //                                 </section>
// //                             </div>
// //                             <div className="col-lg-4 col-md-4">
// //                                 <div className="dash-sidebar">
// //                                     <section>
// //                                         <h5 className="dash-title">Locations</h5>
// //                                         <div className="card">
// //                                             <div className="card-body">
// //                                                 <div className="time-list">
// //                                                     <div className="dash-stats-list">
// //                                                         <h4>71</h4>
// //                                                         <p>Previous Locations</p>
// //                                                     </div>
// //                                                     <div className="dash-stats-list">
// //                                                         <h4>14</h4>
// //                                                         <p>Upcoming Locations</p>
// //                                                     </div>
// //                                                 </div>
// //                                                 <div className="request-btn">
// //                                                     <div className="dash-stats-list">
// //                                                         <h4>2</h4>
// //                                                         <p>Active Locations</p>
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                     </section>
// //                                     <section>
// //                                         <h5 className="dash-title">Your Leave</h5>
// //                                         <div className="card">
// //                                             <div className="card-body">
// //                                                 <div className="time-list">
// //                                                     <div className="dash-stats-list">
// //                                                         <h4>4</h4>
// //                                                         <p>Leave Taken</p>
// //                                                     </div>
// //                                                     <div className="dash-stats-list">
// //                                                         <h4>12</h4>
// //                                                         <p>Remaining</p>
// //                                                     </div>
// //                                                 </div>
// //                                                 <div className="request-btn">
// //                                                     <NavLink className="btn btn-primary" to="/leaves">Apply Leave</NavLink>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                     </section>
// //                                     <section>
// //                                         <h5 className="dash-title">Shared Files</h5>
// //                                         <div className="file-wrap midsfl">
// //                                             <div className="file-cont-wrap">
// //                                                 <div className="file-cont-inner">
// //                                                     <div className="file-content">
// //                                                         <div className="file-body">
// //                                                             <div className="file-scroll">
// //                                                                 <div className="file-content-inner">
// //                                                                     <div className="row row-sm">
// //                                                                         <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
// //                                                                             <div className="card card-file">
// //                                                                                 <div className="dropdown-file">
// //                                                                                     <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
// //                                                                                     <div className="dropdown-menu dropdown-menu-right">
// //                                                                                         <NavLink to="#" className="dropdown-item">View Details</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Share</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Download</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Rename</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Delete</NavLink>
// //                                                                                     </div>
// //                                                                                 </div>
// //                                                                                 <div className="card-file-thumb">
// //                                                                                     <i className="fa fa-file-pdf-o"></i>
// //                                                                                 </div>
// //                                                                                 <div className="card-body">
// //                                                                                     <h6><NavLink to="">Sample.pdf</NavLink></h6>
// //                                                                                     <span>10.45kb</span>
// //                                                                                 </div>
// //                                                                                 <div className="card-footer">
// //                                                                                     <span className="d-none d-sm-inline">Last Modified: </span>1 min ago
// //                                                                                 </div>
// //                                                                             </div>
// //                                                                         </div>
// //                                                                         <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
// //                                                                             <div className="card card-file">
// //                                                                                 <div className="dropdown-file">
// //                                                                                     <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
// //                                                                                     <div className="dropdown-menu dropdown-menu-right">
// //                                                                                         <NavLink to="#" className="dropdown-item">View Details</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Share</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Download</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Rename</NavLink>
// //                                                                                         <NavLink to="#" className="dropdown-item">Delete</NavLink>
// //                                                                                     </div>
// //                                                                                 </div>
// //                                                                                 <div className="card-file-thumb">
// //                                                                                     <i className="fa fa-file-word-o"></i>
// //                                                                                 </div>
// //                                                                                 <div className="card-body">
// //                                                                                     <h6><NavLink to="">Document.docx</NavLink></h6>
// //                                                                                     <span>22.67kb</span>
// //                                                                                 </div>
// //                                                                                 <div className="card-footer">
// //                                                                                     <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
// //                                                                                 </div>
// //                                                                             </div>
// //                                                                         </div>

// //                                                                     </div>
// //                                                                     <div className="request-btn">
// //                                                                         <NavLink className="btn mx-auto btn-primary" to="/file-manager">View All</NavLink>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                         </div>
// //                                     </section>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>
// //             )
// //          }
// //     </>

// //     )
// // }
