import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeScheduleViewBar from '../../components/employeeComponents/employeeSchedule/EmployeeScheduleViewBar'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'

const EmployeeSchedule = () => {
  return (
    <>
      <EmployeeHeader />
      <EmployeeSidebar />
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeScheduleViewBar />
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeSchedule