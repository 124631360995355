import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import TimesheetTable from '../../components/employeeComponents/employeeTimesheet/TimesheetTable'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeTimesheet = () => {
    return (
        <>
            <EmployeeHeader />
            <EmployeeSidebar />
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="Timesheet" timesheet />
                        <TimesheetTable />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeTimesheet