import React from 'react'
import { NavLink } from 'react-router-dom'

const InboxMessages = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="email-header">
                                <div className="row">
                                    <div className="col top-action-left">
                                        <div className="float-left">
                                            <div className="btn-group dropdown-action">
                                                <button type="button" className="btn btn-white dropdown-toggle" data-hrefggle="dropdown">Select <i className="fa fa-angle-down "></i></button>
                                                <div className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">All</NavLink>
                                                    <NavLink className="dropdown-item" to="#">None</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Read</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Unread</NavLink>
                                                </div>
                                            </div>
                                            <div className="btn-group dropdown-action">
                                                <button type="button" className="btn btn-white dropdown-toggle" data-hrefggle="dropdown">Actions <i className="fa fa-angle-down "></i></button>
                                                <div className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">Reply</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Forward</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Archive</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Mark As Read</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Mark As Unread</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Delete</NavLink>
                                                </div>
                                            </div>
                                            <div className="btn-group dropdown-action">
                                                <button type="button" className="btn btn-white dropdown-toggle" data-hrefggle="dropdown"><i className="fa fa-folder"></i> <i className="fa fa-angle-down"></i></button>
                                                <div role="menu" className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">Social</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Forums</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Updates</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Spam</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Trash</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">New</NavLink>
                                                </div>
                                            </div>
                                            <div className="btn-group dropdown-action">
                                                <button type="button" data-hrefggle="dropdown" className="btn btn-white dropdown-toggle"><i className="fa fa-tags"></i> <i className="fa fa-angle-down"></i></button>
                                                <div role="menu" className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">Work</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Family</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Social</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Primary</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Promotions</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Forums</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="float-left d-none d-sm-block">
                                            <input type="text" placeholder="Search Messages" className="form-control search-message" />
                                        </div>
                                    </div>

                                    {/* idhar se masla */}

                                    <div className="col-auto top-action-right">
                                        <div className="text-right">
                                            <button type="button" title="Refresh" data-hrefggle="tooltip" className="btn btn-white d-none d-md-inline-block"><i className="fa fa-refresh"></i></button>
                                            <div className="btn-group">
                                                {/* these buttons were NavLink before */}
                                                <button className="btn btn-white" to=""><i className="fa fa-angle-left"></i></button>
                                                <button className="btn btn-white" to=""><i className="fa fa-angle-right"></i></button>
                                            </div>
                                        </div>

                                        <div className="text-right">
                                            <span className="text-muted d-none d-md-inline-block">Showing 10 of 112 </span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="email-content">
                                <div className="table-responsive">
                                    <table className="table table-inbox table-hover">
                                        <thead>
                                            <tr>
                                                <th colSpan="6">
                                                    <input type="checkbox" className="checkbox-all" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* data-href ki jagah kuch dalna hai */}
                                            <tr className="unread clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star starred"></i></span></td>
                                                <td className="name">John Doe</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td><i className="fa fa-paperclip"></i></td>
                                                <td className="mail-date">13:14</td>
                                            </tr>
                                            <tr className="unread clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">Envato Account</td>
                                                <td className="subject">Important account security update from Envato</td>
                                                <td></td>
                                                <td className="mail-date">8:42</td>
                                            </tr>
                                            <tr className="clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">Twitter</td>
                                                <td className="subject">HRMS Bootstrap Admin Template</td>
                                                <td></td>
                                                <td className="mail-date">30 Nov</td>
                                            </tr>
                                            <tr className="unread clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">Richard Parker</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td></td>
                                                <td className="mail-date">18 Sep</td>
                                            </tr>
                                            <tr className="clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">John Smith</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td></td>
                                                <td className="mail-date">21 Aug</td>
                                            </tr>
                                            <tr className="clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">me, Robert Smith (3)</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td></td>
                                                <td className="mail-date">1 Aug</td>
                                            </tr>
                                            <tr className="unread clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">Codecanyon</td>
                                                <td className="subject">Welcome To Codecanyon</td>
                                                <td></td>
                                                <td className="mail-date">Jul 13</td>
                                            </tr>
                                            <tr className="clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">Richard Miles</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td><i className="fa fa-paperclip"></i></td>
                                                <td className="mail-date">May 14</td>
                                            </tr>
                                            <tr className="unread clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
                                                <td className="name">John Smith</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td></td>
                                                <td className="mail-date">11/11/16</td>
                                            </tr>
                                            <tr className="clickable-row" data-href="mail-view.html">
                                                <td>
                                                    <input type="checkbox" className="checkmail" />
                                                </td>
                                                <td><span className="mail-important"><i className="fa fa-star starred"></i></span></td>
                                                <td className="name">Mike Litorus</td>
                                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                                <td></td>
                                                <td className="mail-date">10/31/16</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InboxMessages