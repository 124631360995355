import {
    MAIL_REQUEST,
    MAIL_SUCCESS,
    MAIL_FAIL,
    MAIL_BY_ID_REQUEST,
    MAIL_BY_ID_SUCCESS,
    MAIL_BY_ID_FAIL,
} from "../constants"

export const mailReducer = (
    state = {
        mail: {
            mailData: null
        }
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case MAIL_REQUEST : 
            return {
                ...state,
            };
        case MAIL_SUCCESS :
            return {
                ...state,
                mailData: action.payload,
            };
        case MAIL_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        case MAIL_BY_ID_REQUEST : 
            return {
                ...state,
            };
        case MAIL_BY_ID_SUCCESS :
            return {
                ...state,
            };
        case MAIL_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}