import React from 'react'

const Settings = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="page-title">Settings</h3>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>First Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" value="Jhon"/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input className="form-control " value="Porter" type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input className="form-control " value="3864 Quiet Valley Lane, Sherman Oaks, CA, 91403" type="text"/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select className="form-control select">
                                        <option>USA</option>
                                        <option>United Kingdom</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>City</label>
                                    <input className="form-control" value="Sherman Oaks" type="text"/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>State/Province</label>
                                    <select className="form-control select">
                                        <option>California</option>
                                        <option>Alaska</option>
                                        <option>Alabama</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>Postal Code</label>
                                    <input className="form-control" value="91403" type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" value="danielporter@example.com" type="email"/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input className="form-control" value="612-123-4567" type="text"/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Mobile Number</label>
                                    <input className="form-control" value="612-123-4567" type="text"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Change Password</label>
                                    <input className="form-control" value="818-978-7102" type="password"/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input className="form-control" value="818-978-7102" type="password"/>
                                </div>
                            </div>
                        </div>
                        <div className="submit-section">
                            <button className="btn btn-primary submit-btn">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Settings