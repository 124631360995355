import React, { forwardRef, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { shiftSearchFilter } from "../../../actions/shiftActions"
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const EmployeeScheduleSearch = () => {
    

    const auth = useSelector((state) => state.auth);
    const locationTemp = useSelector((state) => state.location);
    // const [fromDate, setFromDate] = useState(new Date());
    let location = locationTemp.location
    const dispatch = useDispatch()
    location.forEach(element => {
        console.log(element.name)
    });
    const [values, setValues] = useState({
        location: ''
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const From = forwardRef(({ value, onClick }, ref) => (
        // <div className="col-sm-6 col-md-2" >
            <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
                <div className="cal-icon" >
                    <input className="form-control floating datetimepicker" type="text" value={value} />
                </div>
                <label className="focus-label">From</label>
            </div>
        // </div>
    ));
    const To = forwardRef(({ value, onClick }, ref) => (
        // <div className="col-sm-6 col-md-2" >
            <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
                <div className="cal-icon" >
                    <input className="form-control floating datetimepicker" type="text" value={value} />
                </div>
                <label className="focus-label">To</label>
            </div>
        // </div>
    ));
    console.log(fromDate, toDate, values)
    let searchData;
    if (values.location !== ''){
        searchData = {
            companyId: auth.user.companyId,
            name: auth.user.firstName + " " + auth.user.lastName,
            date: moment().format('YYYY-MM-DD'),
            location: values.location,
            from: fromDate.toISOString(),
            to: toDate.toISOString()
        }
    }
    console.log(searchData)
    const handleClick = async (event) => {
        event.preventDefault()
        dispatch(shiftSearchFilter(searchData))
    }
    return (
        <>
            {/* <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid"> */}
            {/* {location.map((current, i) => {

            <h1>{current.name}</h1>
            })} */}
            <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus">
                        <input type="text" className="form-control floating" disabled />
                        <label className="focus-label">{auth.user.firstName} {auth.user.lastName}</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-floating">
                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                        type="text"
                        autoComplete="false" value={values.location}
                        onChange={handleChange('location')}
                        >
                            <option value=''>All Locations</option>
                            { location.length > 0 &&
                                location.map((current, i) => {
                                    return (
                                        <option value={current.id} key={i+1}>{current.name}</option>
                                    )
                                })
                            }
                            {/* <option value="2">Head Office</option>
                            <option value="3">West Office</option>
                            <option value="4">66 West</option>
                            <option value="5">Museum</option> */}
                        </select>
                        <label htmlFor="floatingSelect">Locations</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">

                    <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    customInput={<From />}
                    />
                    {/* <div className="form-group form-focus focused" >
                        <div className="cal-icon" >
                            <input className="form-control floating datetimepicker" type="text" />
                        </div>
                        <label className="focus-label">From</label>
                    </div> */}
                </div>



                <div className="col-sm-6 col-md-2">
                <DatePicker
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    customInput={<To />}
                />
                    {/* <div className="form-group form-focus focused">
                        <div className="cal-icon">
                            <input className="form-control floating datetimepicker" type="text" />
                        </div>
                        <label className="focus-label">To</label>
                    </div> */}
                </div>
                <div className="col-sm-6 col-md-2">
                    <a onClick={handleClick} className="btn btn-success btn-block"> Search </a>
                </div>
            </div>

            {/* *************************************************** */}
     


            {/* </div>
        </div>
    </div> */}
        </>
    )
}

export default EmployeeScheduleSearch


// import  { axios } from 'axios'
    // import { useSelector } from 'react-redux'
    // import  { SERVERADDRESS } from '../../../constants'
    
    
    // const [ location, setLoction ] = useState([]);
    // const [ error, setError ] = useState("");
    // const auth = useSelector((state) => state.auth);
    // const locationApi = async () => {
    //     try {
    //         const res = await axios.post(`${SERVERADDRESS}v1/location/getAllLocations`, 
    //         { companyId : auth.user.companyId}
    //         )
    //         setLoction(res.data);
    //     } catch (error) {
    //         setError(error.message)
    //     }
    // }
    // useEffect(() => {
    //     locationApi()
    // }, [])
    // const dispatch = useDispatch()
    // const locationData = async (e) => {
    //     await dispatch(getCompanyLocations({companyId: auth.user.companyId}))
    // }
    // useEffect(() => {
    //     locationData()
    // },[])