import axios from "axios";
import {
    ANNIVARSARY_REQUEST,
    ANNIVARSARY_SUCCESS,
    ANNIVARSARY_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY LOCATION
export const employmentAnnivarsary = (obj) => async (dispatch) => {
    dispatch({
        type: ANNIVARSARY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/users/employmentAnnivarsary`, obj);
        localStorage.setItem("annivarsary", JSON.stringify(data));
        dispatch({
            type: ANNIVARSARY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ANNIVARSARY_FAIL,
            payload: err,
        });
    }
};
