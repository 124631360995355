import React from 'react'
import { NavLink } from 'react-router-dom'

const LocationUploadedFiles = () => {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title m-b-20">Uploaded files</h5>
                    <ul className="files-list">
                        <li>
                            <div className="files-cont">
                                <div className="file-type">
                                    <span className="files-icon"><i className="fa fa-file-pdf-o"></i></span>
                                </div>
                                <div className="files-info">
                                    <span className="file-name text-ellipsis"><NavLink to="#">AHA Selfcare Mobile Application Test-Cases.xls</NavLink></span>
                                    <span className="file-author"><NavLink to="#">John Doe</NavLink></span> <span className="file-date">May 31st at 6:53 PM</span>
                                    <div className="file-size">Size: 14.8Mb</div>
                                </div>
                                <ul className="files-action">
                                    <li className="dropdown dropdown-action">
                                        <NavLink to="" className="dropdown-toggle btn btn-link" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_horiz</i></NavLink>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <NavLink className="dropdown-item" to="javascript:void(0)">Download</NavLink>
                                            <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#share_files">Share</NavLink>
                                            <NavLink className="dropdown-item" to="javascript:void(0)">Delete</NavLink>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="files-cont">
                                <div className="file-type">
                                    <span className="files-icon"><i className="fa fa-file-pdf-o"></i></span>
                                </div>
                                <div className="files-info">
                                    <span className="file-name text-ellipsis"><NavLink to="#">AHA Selfcare Mobile Application Test-Cases.xls</NavLink></span>
                                    <span className="file-author"><NavLink to="#">Richard Miles</NavLink></span> <span className="file-date">May 31st at 6:53 PM</span>
                                    <div className="file-size">Size: 14.8Mb</div>
                                </div>
                                <ul className="files-action">
                                    <li className="dropdown dropdown-action">
                                        <NavLink to="" className="dropdown-toggle btn btn-link" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_horiz</i></NavLink>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <NavLink className="dropdown-item" to="javascript:void(0)">Download</NavLink>
                                            <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#share_files">Share</NavLink>
                                            <NavLink className="dropdown-item" to="javascript:void(0)">Delete</NavLink>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LocationUploadedFiles