import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getLeaveByUserId } from "../../../actions/leaveActions"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';

const LeavesTable = () => {
    const auth = useSelector((state) => state.auth);
    const leave = useSelector((state) => state.leave);
    const dispatch = useDispatch()
    const leaveListData = async (e) => {
        dispatch(getLeaveByUserId({userId: auth.user.id}))
    }
    useEffect(() => {
        leaveListData()
    },[])

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th>Leave Type</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>No of Days</th>
                                    <th>Reason</th>
                                    <th className="text-center">Status</th>
                                    <th>Approved by</th>
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                leave.leaveList &&
                                    leave.leaveList.length > 0 &&
                                        leave.leaveList.map((current, i) => {
                                            return(
                                                <tr key={i} 
                                                // style={{maxWidth: "100%"}}
                                                >
                                                    <td>{current.leaveType.name}</td>
                                                    <td>{moment(current.from).format('DD MMM YYYY')}</td>
                                                    <td>{moment(current.to).format('DD MMM YYYY')}</td>
                                                    <td>{current.numberOfDays} days</td>
                                                    <td 
                                                    style={{minWidth: "150px", maxWidth: "250px"}}
                                                    >{current.leaveReason}</td>
                                                    <td className="text-center">
                                                        {
                                                            current.status === "Pending" ?
                                                                <div className="action-label">
                                                                    &nbsp; 
                                                                    &nbsp; 
                                                                    &nbsp; 
                                                                    <a className="btn btn-white btn-sm btn-rounded" > 
                                                                    {/* //to="javascript:void(0);" */}
                                                                        <i className="fa fa-dot-circle-o text-purple"></i> Pending
                                                                    </a>
                                                                </div>
                                                            : 
                                                            current.status === "Approved" ?
                                                                <div className="action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded">
                                                                        <i className="fa fa-dot-circle-o text-success"></i> Approved
                                                                    </a>
                                                                </div>
                                                            :
                                                            <div className="action-label">
                                                                <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                                    <i className="fa fa-dot-circle-o text-danger"></i> Declined
                                                                </NavLink>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></a>
                                                            <a to="#"></a>
                                                        </h2>
                                                    </td>

                                                </tr>
                                            )
                                        })
                            }
                                    {/* <tr>
                                    <td>Medical Leave</td>
                                    <td>27 Feb 2021</td>
                                    <td>27 Feb 2021</td>
                                    <td>1 day</td>
                                    <td>Going to Hospital</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-success"></i> Approved
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>LOP</td>
                                    <td>24 Feb 2021</td>
                                    <td>25 Feb 2021</td>
                                    <td>2 days</td>
                                    <td>Personnal</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-success"></i> Approved
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Paternity Leave</td>
                                    <td>13 Feb 2021</td>
                                    <td>17 Feb 2021</td>
                                    <td>5 days</td>
                                    <td>Going to Hospital</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-danger"></i> Declined
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Casual Leave</td>
                                    <td>30 Jan 2021</td>
                                    <td>30 Jan 2021</td>
                                    <td>Second Half</td>
                                    <td>Going to Hospital</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-purple"></i> New
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Hospitalisation</td>
                                    <td>15 Jan 2021</td>
                                    <td>25 Jan 2021</td>
                                    <td>10 days</td>
                                    <td>Going to Hospital</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-success"></i> Approved
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Casual Leave</td>
                                    <td>13 Jan 2021</td>
                                    <td>14 Jan 2021</td>
                                    <td>2 days</td>
                                    <td>Going to Hospital</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-success"></i> Approved
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Casual Leave</td>
                                    <td>10 Jan 2021</td>
                                    <td>10 Jan 2021</td>
                                    <td>First Half</td>
                                    <td>Going to Hospital</td>
                                    <td className="text-center">
                                        <div className="action-label">
                                            <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);">
                                                <i className="fa fa-dot-circle-o text-danger"></i> Declined
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink>
                                            <NavLink to="#">Richard Miles</NavLink>
                                        </h2>
                                    </td>

                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeavesTable