import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import EmployeeTitle from '../EmployeeTitle'
import EmployeeScheduleDaily from './EmployeeScheduleDaily'
import EmployeeScheduleMonthly from './EmployeeScheduleMonthly'
import EmployeeScheduleSearch from './EmployeeScheduleSearch'
import EmployeeScheduleWeekly from './EmployeeScheduleWeekly'

const EmployeeScheduleViewBar = () => {
    
    const [ viewCalender, setViewCalender ] = useState("weekly")
    const handleDaily = (e) => {
        setViewCalender("daily")
    }
    const handleWeek = (e) => {
        setViewCalender("weekly")
    }
    const handleMonthly= (e) => {
        setViewCalender("monthly")
    }
    var currDate = new Date("2022-07-29");
    var today = new Date("2022-07-29");
    var firstDate, lastDate;
    let dateAction
    // let tempW1, tempW2, tempM, tempT;
    // tempT = currDate.toString().split(" ")
    var weekFirst = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+1));
    var weekLast = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+7));
    // tempW1 = weekFirst.toString().split(" ")
    // tempW2 = weekLast.toString().split(" ")

    let weekdate = []
    let monthdate = []
    let dailydate = []
    let temp, arr, arr2, i;
    arr2 = today.toString().split(" ");
    dailydate.push({day: arr2[0], date: arr2[2]})

    // let arr
    // let i;
    for (i = 1; i < 8; i++){
        // temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()))
        // arr = temp.toString().split(" ");
        // console.log(arr[2])
         currDate = new Date("2022-07-29");
        // console.log(currDate)
        temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+i))
        // console.log(temp)
        arr = temp.toString().split(" ");
        // console.log(arr)
        weekdate.push({day: arr[0], date: arr[2]})
    }
    // console.log(weekdate,"88")
    var month = currDate.getMonth()+1;
  var year = currDate.getFullYear();
   firstDate = new Date(year, month-1);
//   tempM = firstDate.toString().split(" ")
   lastDate = new Date(year, month, 0);
  temp = lastDate.toString().split(" ")
  let limit = temp[2]
  limit = parseInt(limit)
  for (i = 0; i < limit; i++){
      firstDate = new Date(year, month-1);
      temp = new Date(firstDate.setDate(firstDate.getDate()+i))
      arr = temp.toString().split(" ");
      monthdate.push({day: arr[0], date:arr[2]})
  }
// console.log(monthdate)
    return (
        <>
        {
            viewCalender === "weekly" ? 
            <>
            <EmployeeTitle title="Weekly Scheduling" schedule/>
            <EmployeeScheduleSearch />
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={handleDaily}
                            // to="/daily-scheduling" 
                            className="btn">Daily</button>
                        <button
                            onClick={handleWeek}
                            // to="/daily-scheduling" 
                            className="btn"
                            style={{backgroundColor:"#ff9b44", color:"white"}}>Weekly</button>
                        <button
                            onClick={handleMonthly}
                            // to="/daily-scheduling" 
                            className="btn">Monthly</button>
                        {/* <NavLink to="/shift-scheduling" className="btn btn-primary">Weekly</NavLink>
                        <NavLink to="/monthly-scheduling" className="btn">Monthly</NavLink> */}
                    </div>
                </div>
                <div className="col-md-4">
                    <h3 className="card-title mt-2 text-center"> {weekFirst.getDate()+ " " + weekFirst.toLocaleString('default', { month: 'long' }) + " " + 
                    "to " + weekLast.getDate()+ " " + weekLast.toLocaleString('default', { month: 'long' }) + " "}
                    {weekFirst.getFullYear() === weekLast.getFullYear() ?
                    weekFirst.getFullYear() : weekFirst.getFullYear()+"-"+weekLast.getFullYear()
                    } 
                    {/* {tempW1[2]} {tempW1[1]}  */}
                    {/* to  */}
                    {/* {tempW2[2]} {tempW2[1]}, 
                    {tempW1[3] === tempW2[3] ?
                    " "+tempW1[3] : " "+tempW1[3]-tempW2[3]} */}
                    </h3>
                </div>

                <div className="col-md-4">
                    <div className="float-right">
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>

                    </div>
                </div>
            </div>
            <EmployeeScheduleWeekly weekdate={weekdate}/>
            </>
            : viewCalender === "daily" ? 
            <>
            <EmployeeTitle title="Daily Scheduling" schedule/>
            <EmployeeScheduleSearch />
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={handleDaily}
                            className="btn"
                            style={{backgroundColor:"#ff9b44", color:"white"}}>Daily</button>
                        <button
                            onClick={handleWeek}
                            className="btn">Weekly</button>
                        <button
                            onClick={handleMonthly}
                            className="btn">Monthly</button>
                    </div>
                </div>
                <div className="col-md-4">
                    <h3 className="card-title mt-2 text-center">{today.getDate() + " " + today.toLocaleString('default', { month: 'long' }) + " " + today.getFullYear()}</h3>
                </div>

                <div className="col-md-4">
                    <div className="float-right">
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>

                    </div>
                </div>
            </div>
            <EmployeeScheduleDaily dailydate={dailydate}/>
            </> 
            : viewCalender === "monthly" ? 
            <>
            <EmployeeTitle title="Monthly Scheduling" schedule/>
            <EmployeeScheduleSearch />
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={handleDaily}
                            className="btn">Daily</button>
                        <button
                            onClick={handleWeek}
                            className="btn">Weekly</button>
                        <button
                            onClick={handleMonthly}
                            className="btn"
                            style={{backgroundColor:"#ff9b44", color:"white"}}>Monthly</button>
                    </div>
                </div>
                <div className="col-md-4">
                    <h3 className="card-title mt-2 text-center">{firstDate.toLocaleString('default', { month: 'long' }) + " " + firstDate.getFullYear()}</h3>
                </div>

                <div className="col-md-4">
                    <div className="float-right">
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>

                    </div>
                </div>
            </div>
            <EmployeeScheduleMonthly monthdate={monthdate}/>
            </>
            :
            <div>nothing</div>
            } 
        </>
    )
}

export default EmployeeScheduleViewBar




// kaam ka code

// const [ viewCalender, setViewCalender ] = useState("weekly")
//     const [ dailyUpdate, setDailyUpdate ] = useState(0)
//     const [ weeklyUpdate, setWeeklyUpdate ] = useState(0)
//     const [ monthUpdate, setMonthUpdate ] = useState(0)
//     let dataArr;
//     var currDate = new Date("2022-07-29");
//     var today = new Date("2022-07-29");
//     var month = currDate.getMonth()+1;
//     let cond;
//   var year = currDate.getFullYear();
//     var firstDate, lastDate;
//     const handleDaily = (e) => {
//         setViewCalender("daily")
//     }
//     const handleWeek = (e) => {
//         setViewCalender("weekly")
//     }
//     const handleMonthly= (e) => {
//         setViewCalender("monthly")
//     }
//     const p = async () => {
//         console.log("currDate")
//     }
//     const handleDailyUpdateInc = async (e) => {

//         setDailyUpdate(dailyUpdate + 1)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+dailyUpdate));
//         console.log(currDate)
//     }
//     const handleWeeklyUpdateInc = async (e) => {
//         setWeeklyUpdate(weeklyUpdate + 7)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+weeklyUpdate));
//         console.log(currDate)
//         for (i = 1; i < 8; i++){
//             // temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()))
//             // arr = temp.toString().split(" ");
//             // console.log(arr[2])
//              currDate = new Date("2022-07-29");
//             // console.log(currDate)
//             temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+i))
//             // console.log(temp)
//             arr = temp.toString().split(" ");
//             // console.log(arr)
//             cond.push({day: arr[0], date: arr[2]})
//         }
// console.log("conddddddddddddddddddddddddddddd", cond)

//     }
//     const handleMonthUpdateInc = async (e) => {
//         setMonthUpdate(monthUpdate + 1)
//         month = currDate.getMonth()+1 + monthUpdate;
//         if (month > 12){
//             month = 1
//             year = currDate.getFullYear()+1;
//             setMonthUpdate(0)
//         }
//         else {
//             year = currDate.getFullYear();
//         }
//         firstDate = new Date(year, month-1);
//         console.log(month, year)
//         lastDate = new Date(year, month, 0);
//         console.log(firstDate, lastDate)
//     }
//     const handleDailyUpdateDec = async (e) => {
//         setDailyUpdate(dailyUpdate - 1)
//         console.log(dailyUpdate)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+dailyUpdate));
//         console.log(currDate)
        
//     }
//     const handleWeeklyUpdateDec = async (e) => {
//         setWeeklyUpdate(weeklyUpdate - 7)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+weeklyUpdate));
//         console.log(currDate)
//     }
//     const handleMonthUpdateDec = async (e) => {
//         setMonthUpdate(monthUpdate -1)
//         month = currDate.getMonth()+1+monthUpdate;
//         if (month < 0){
//             month = 11
//             year = currDate.getFullYear()-1;
//             setMonthUpdate(0)
//         }
//         else {
//             year = currDate.getFullYear();
//         }
//         year = currDate.getFullYear();
//         firstDate = new Date(year, month-1);
//         lastDate = new Date(year, month, 0);
//         console.log(month, year)
//         console.log(firstDate, lastDate)
//     }