import React from 'react'
import Login from '../components/Login'

function EmployeeLogin() {
  return (

    <>
      <Login />
    </>
  )
}

export default EmployeeLogin