import axios from "axios";
import {
    LEAVE_ANNUAL_STATUS_REQUEST,
    LEAVE_ANNUAL_STATUS_SUCCESS,
    LEAVE_ANNUAL_STATUS_FAIL,
    LEAVE_REQUEST,
    LEAVE_SUCCESS,
    LEAVE_FAIL,
    LEAVE_CREATE_REQUEST,
    LEAVE_CREATE_SUCCESS,
    LEAVE_CREATE_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER ANNUAL LEAVE STATUS
export const getUserAnnualStatusByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_ANNUAL_STATUS_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getUserAnnualStatusByUserId`, obj);
        localStorage.setItem("leaveAnnualStatus", JSON.stringify(data));
        dispatch({
            type: LEAVE_ANNUAL_STATUS_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_ANNUAL_STATUS_FAIL,
            payload: err,
        });
    }
};

// USER LEAVE LIST
export const getLeaveByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getLeaveByUserId`, obj);
        localStorage.setItem("leaveList", JSON.stringify(data));
        dispatch({
            type: LEAVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_FAIL,
            payload: err,
        });
    }
};

// CREATE USER NEW LEAVE 
export const createLeave = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_CREATE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/createLeave`, obj);
        // localStorage.setItem("leaveList", JSON.stringify(data));
        dispatch({
            type: LEAVE_CREATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_CREATE_FAIL,
            payload: err,
        });
    }
};


