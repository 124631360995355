import {
    USER_AUTH_REQUEST,
    USER_AUTH_SUCCESS,
    USER_AUTH_FAIL,
    USER_LOGOUT_SUCCESS,
    GET_USER_FAIL,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
} from "../constants"

// const initialState = {
//     auth: {
//         tokens: localStorage.getItem("tokens")
//             ? JSON.parse(localStorage.getItem("tokens"))
//             : null,
//         user: null,
//         loading: false,        
//     },
// };

export const authReducer = (
    state = {
        auth: {
            user: {},
            tokens: null,
            loading: false,
        },
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case USER_AUTH_REQUEST : 
            return {
                loading: true,
            };
        case USER_AUTH_SUCCESS :
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                tokens: action.payload.tokens,
                // newRegisteration: action.payload.register ? true : false,
            };
        case USER_AUTH_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT_SUCCESS:
            return {
                user: null,
                tokens: null,
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                tokens: action.payload,
            };
        case GET_USER_FAIL:
            return {
                ...state,
                user: null,
                tokens: null,
            };
        default:
            return state;
    }
}