import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

const LeavesTypes = () => {
    const leave = useSelector((state) => state.leave);
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3 className="page-title mt-5">Aprroved Leaves Type</h3>
                    <div className="table-responsive">
                        <table className="table table-striped custom-table mb-0">
                            <thead>
                                <tr>
                                    <th>Leave Types</th>
                                    <th className="text-center">Total Leave</th>
                                    <th className="text-center">Remaining Leave</th>
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                leave.leaveAnnualStatus &&
                                    leave.leaveAnnualStatus.length > 0 &&
                                        leave.leaveAnnualStatus.map((current, i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>
                                                        <strong>{current.leaveCategory.name}</strong>
                                                    </td>
                                                    <td className="text-center">{current.annualLeavesDays}</td>
                                                    <td className="text-center">{current.remainingLeavesDays}</td>
                                                </tr>
                                            )
                                            })
                                }
                                {/* <tr>
                                    <td>
                                        <strong>Vacation</strong>
                                    </td>
                                    <td className="text-center">10</td>
                                    <td className="text-center">5</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeavesTypes