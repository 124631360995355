import React from 'react'
import { NavLink } from 'react-router-dom'
import { shiftPageData } from "../../../actions/shiftActions"
import { useSelector, useDispatch } from 'react-redux'

const EmployeeScheduleDaily = ({dailydate}) => {
    const data = JSON.parse(window.localStorage.getItem('weeklyShift'))
    const auth = useSelector((state) => state.auth);
    let arr = []
    let temp;
    for (let i=0; i<dailydate.length; i++){
        temp = parseInt(dailydate[i].date)
        data.forEach(e => {
            if (e.dateDD === temp){
                arr[i] = e
            }
            else {
                arr[i] = {}
            }
        });
    }
    console.log(dailydate,"dailydatedailydatedailydatedailydate")
    return (
        <>
            <div className="row miordr2">
                <div className="col-md-12">
                    <div className="table-responsive misftsch misftschdl">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                                <tr>
                                    <th>Scheduled Shift</th>
                                    {dailydate.map((current, i) => {
                                        return (
                                            <th>{current.day}<br /> {current.date}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
                                            <NavLink to="/profile">{auth.user.firstName} {auth.user.lastName} <span>{auth.user.jobTitle}</span></NavLink>
                                        </h2>
                                    </td>
                                    {
                                        arr.map((current, i) =>{
                                            let shiftType;
                                            let shiftTypeCss = "miclsun";
                                            let shiftTypeIcon = "fa fa-sun-o";
                                            let empty = true
                                            let temp;
                                            let start, end;
                                            let stAmPm = "am";
                                            let enAmPm = "am";
                                            let date;
                                            if (Object.keys(current).length != 0){
                                                let stStart = parseInt(current.startTime.substring(11, 13))
                                                let stStartMin = parseInt(current.startTime.substring(14, 16))
                                                let enEnd = parseInt(current.endTime.substring(11, 13))
                                                let enEndMin = parseInt(current.endTime.substring(14, 16))
                                                if (stStartMin < 10) {
                                                    stStartMin = "0"+stStartMin
                                                }
                                                if (enEndMin < 10){
                                                    enEndMin = "0"+enEndMin
                                                }
                                                if (stStart === 0){
                                                    stStart = 12
                                                }
                                                if (enEnd === 0){
                                                    enEnd = 12
                                                }
                                                if (stStart > 12){
                                                    stStart = stStart - 12
                                                    stAmPm = "pm"
                                                }
                                                if (enEnd > 12){
                                                    enEnd = enEnd - 12
                                                    enAmPm = "pm"
                                                }
                                                if (stStart >= 6 && stAmPm == "am"){
                                                    shiftType = "morning"
                                                    shiftTypeCss="miclsun" 
                                                    shiftTypeIcon = "fa fa-sun-o"
                                                }
                                                else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm"){
                                                    shiftType = "evening"
                                                    shiftTypeCss="miclscl"
                                                    shiftTypeIcon = "fa fa-cloud" 
                                                }
                                                else if (stStart >= 8 && stAmPm == "pm"){
                                                    shiftType = "night"
                                                    shiftTypeCss="miclsmn"
                                                    shiftTypeIcon = "fa fa-moon-o"
                                                }
                                                else if (stStart >= 0 && stAmPm == "am"){
                                                    shiftType = "night"
                                                    shiftTypeCss="miclsmn"
                                                    shiftTypeIcon = "fa fa-moon-o"
                                                }
                                                date = stStart + ":" + stStartMin + " " + stAmPm + "-" + enEnd + ":" + enEndMin + " " + enAmPm;
                                            }
                                            
                                            return (
                                                <>
                                                    {
                                                        Object.keys(current).length != 0
                                                            ?    
                                                            <td colspan="6" key={i}>
                                                                <div className={`user-add-shedule-list ${shiftTypeCss}`} style={{width: "100%"}}>
                                                                    <h2>

                                                                        <NavLink to="/location-view">
                                                                            <span className="username-info ">{date} ({current.duration}) {auth.user.jobTitle} - {current.location.name} <i className={shiftTypeIcon}></i></span>
                                                                        </NavLink>
                                                                    </h2>
                                                                </div>
                                                            </td>
                                                            :
                                                            <td style={{minWidth: "6rem"}} key={i}>
                                                                <div className="user-add-shedule-list">
                                                                <h2>
                                                                    <NavLink to="/location-view">
                                                                        
                                                                    </NavLink>
                                                                </h2>
                                                            </div>
                                                        </td> 
                                                    }    
                                                </>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeScheduleDaily


// return (
//     <>
//         {/* <div className="main-wrapper"> */}

//         {/* <div className="page-wrapper">
//             <div className="content container-fluid"> */}
//         {/* <div className="page-header">
//         <div className="row">
//         <div className="col">
//         <h3 className="page-title">Daily Scheduling</h3>
//         <ul className="breadcrumb">
//             <li className="breadcrumb-item"><NavLink to="index.html">Dashboard</NavLink></li>
//             <li className="breadcrumb-item active">Daily Scheduling</li>
//         </ul>
//         </div>
//         <div className="col-auto float-right ml-auto">
//         <NavLink to="calendar.html" className="btn add-btn"><i className="la la-calendar"></i> Calender</NavLink>
//         </div>
//         </div>
//         </div>
//         <div className="row filter-row">
//         <div className="col-sm-6 col-md-3">
//         <div className="form-group form-focus">
//         <input type="text" className="form-control floating" disabled>
//         <label className="focus-label">Ali, Abdirahman</label>
//         </div>
//         </div>
//         <div className="col-sm-6 col-md-3">
//         <div className="form-group form-focus select-focus">
//         <select className="select floating">
//             <option>All Locations</option>
//             <option value="2">Head Office</option>
//         <option value="3">West Office</option>
//         <option value="4">66 West</option>
//         <option value="5">Museum</option>
//         </select>
//         <label className="focus-label">Locations</label>
//         </div>
//         </div>
//         <div className="col-sm-6 col-md-2">
//         <div className="form-group form-focus focused">
//         <div className="cal-icon">
//             <input className="form-control floating datetimepicker" type="text">
//         </div>
//         <label className="focus-label">From</label>
//         </div>
//         </div>
//         <div className="col-sm-6 col-md-2">
//         <div className="form-group form-focus focused">
//         <div className="cal-icon">
//             <input className="form-control floating datetimepicker" type="text">
//         </div>
//         <label className="focus-label">To</label>
//         </div>
//         </div>
//         <div className="col-sm-6 col-md-2">
//         <NavLink to="#" className="btn btn-success btn-block"> Search </NavLink>
//         </div>
//         </div> */}
//         {/* <div className="row mitblrow">
//             <div className="col-md-4">
//                 <div className="float-left">
//                     <NavLink to="daily-scheduling.html" className="btn btn-primary">Daily</NavLink>
//                     <NavLink to="shift-scheduling.html" className="btn ">Weekly</NavLink>
//                     <NavLink to="monthly-scheduling.html" className="btn">Monthly</NavLink>
//                 </div>
//             </div>
//             <div className="col-md-4">
//                 <h3 className="card-title mt-2 text-center">27 August 2021</h3>
//             </div>

//             <div className="col-md-4">
//                 <div className="float-right">
//                     <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
//                     <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>

//                 </div>
//             </div>
//         </div> */}
//         <div className="row miordr2">
//             <div className="col-md-12">
//                 <div className="table-responsive misftsch misftschdl">
//                     <table className="table table-striped custom-table datatable">
//                         <thead>
//                             <tr>
//                                 <th>Scheduled Shift</th>
//                                 <th>00</th>
//                                 <th>01</th>
//                                 <th>02</th>
//                                 <th>03</th>
//                                 <th>04</th>
//                                 <th>05</th>
//                                 <th>06</th>
//                                 <th>07</th>
//                                 <th>08</th>
//                                 <th>09</th>
//                                 <th>10</th>
//                                 <th>11</th>
//                                 <th>12</th>
//                                 <th>13</th>
//                                 <th>14</th>
//                                 <th>15</th>
//                                 <th>16</th>
//                                 <th>17</th>
//                                 <th>18</th>
//                                 <th>19</th>
//                                 <th>20</th>
//                                 <th>21</th>
//                                 <th>22</th>
//                                 <th>23</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>
//                                 <td colspan="6">
//                                     <div className="user-add-shedule-list miclsmn">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td colspan="6">
//                                     <div className="user-add-shedule-list miclsun">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-sun-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td colspan="8">
//                                     <div className="user-add-shedule-list miclscl">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>







//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>
//                                 <td colspan="12">
//                                     <div className="user-add-shedule-list miclsmn">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>


//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td colspan="10">
//                                     <div className="user-add-shedule-list miclscl">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>


//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td colspan="10">
//                                     <div className="user-add-shedule-list miclsun">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-sun-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>

//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>
//                                 <td colspan="6">
//                                     <div className="user-add-shedule-list miclsmn">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td colspan="6">
//                                     <div className="user-add-shedule-list miclsun">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-sun-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td colspan="8">
//                                     <div className="user-add-shedule-list miclscl">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>







//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>
//                                 <td colspan="12">
//                                     <div className="user-add-shedule-list miclsmn">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-moon-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>


//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td colspan="10">
//                                     <div className="user-add-shedule-list miclscl">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>


//                             <tr>
//                                 <td>
//                                     <h2 className="table-avatar">
//                                         <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                         <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                                     </h2>
//                                 </td>

//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td colspan="10">
//                                     <div className="user-add-shedule-list miclsun">
//                                         <h2>
//                                             <NavLink to="/location-view">
//                                                 <span className="username-info ">6: 30 am - 9: 30 pm (14 hrs 15 mins) Un-Armed Officer - 66West <i className="fa fa-sun-o"></i></span>

//                                             </NavLink>
//                                         </h2>
//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                                 <td>
//                                     <div className="user-add-shedule-list">

//                                     </div>
//                                 </td>
//                             </tr>


//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//         {/* </div>
//     </div> */}
//         {/* <div id="add_schedule" className="modal custom-modal fade" role="dialog">
//             <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title">Add Shifts</h5>
//                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                             <span aria-hidden="true">&times;</span>
//                         </button>
//                     </div>
//                     <div className="modal-body">
//                         <form>
//                             <h4>Create Shift on 22-August</h4>
//                             <div className="row">
//                                 <div className="col-sm-5">
//                                     <div className="form-group">
//                                         <label className="col-form-label">Start Time <span className="text-danger">*</span></label>
//                                         <div className="colo-icon"><input className="form-control datetimepicker3" type="text" /></div>
//                                     </div>
//                                 </div>
//                                 <div className="col-sm-5">
//                                     <div className="form-group">
//                                         <label className="col-form-label">End Time <span className="text-danger">*</span></label>
//                                         <div className="colo-icon"><input className="form-control datetimepicker3" type="text" /></div>
//                                     </div>
//                                 </div>
//                                 <div className="col-sm-2">
//                                     <h4 className="mihour">8.5h</h4>
//                                 </div>
//                                 <div className="col-sm-6">
//                                     <div className="form-group">
//                                         <label className="col-form-label">Location <span className="text-danger">*</span></label>
//                                         <select className="select">
//                                             <option value="">Select</option>
//                                             <option value="1">Armed - 66 west</option>
//                                             <option value="2">Officer - 66 west</option>
//                                             <option value="3">Armed - Paul Vista</option>
//                                             <option value="4">Officer - Paul Vista</option>
//                                         </select>
//                                     </div>
//                                 </div>
//                                 <div className="col-sm-6">
//                                     <div className="form-group">
//                                         <label className="col-form-label">Break Rule <span className="text-danger">*</span></label>
//                                         <select className="select">
//                                             <option value="">Select</option>
//                                             <option value="1">London Rule</option>
//                                             <option value="2">Act 193</option>
//                                         </select>
//                                     </div>
//                                 </div>

//                                 <div className="col-sm-12 mb-3">
//                                     <div className="submit-section mt-1">
//                                         <button className="btn btn-info submit-btn">Create 18:00-03:00 Shift</button>
//                                     </div>
//                                 </div>
//                             </div>

//                             <h4>Or Create Shift from template</h4>
//                             <div className="row">
//                                 <div className="col-sm-12">
//                                     <div className="form-group">
//                                         <label className="col-form-label">Shift Template <span className="text-danger">*</span></label>
//                                         <select className="select">
//                                             <option value="">Select</option>
//                                             <option value="1">18:00-03:00 Armed - 66 west</option>
//                                             <option value="2">18:00-03:00 Officer - 66 west</option>
//                                             <option value="3">18:00-03:00 Armed - Paul Vista</option>
//                                             <option value="4">18:00-03:00 Officer - Paul Vista</option>
//                                         </select>
//                                     </div>
//                                 </div>
//                                 <div className="col-sm-12">
//                                     <div className="submit-section mt-1">
//                                         <button className="btn btn-info submit-btn">Select 18:00-03:00 Shift</button>
//                                     </div>
//                                 </div></div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div> */}
//         {/* <div id="edit_schedule" className="modal custom-modal fade" role="dialog">
//            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
//               <div className="modal-content">
//                  <div className="modal-header">
//                     <h5 className="modal-title">Edit Schedule</h5>
//                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                     <span aria-hidden="true">&times;</span>
//                     </button>
//                  </div>
//                  <div className="modal-body">
//                     <form>
//                        <div className="row">
//                        <div className="col-sm-8">
//                        <div className="row">
//                        <div className="col-sm-6">
//                              <div className="form-group">
//                                 <label className="col-form-label">From Date <span className="text-danger">*</span></label>
//                                 <div className="cal-icon"><input className="form-control datetimepicker" type="text" value="26/08/2021"/></div>
//                              </div>
//                           </div>
//                           <div className="col-sm-6">
//                              <div className="form-group">
//                                 <label className="col-form-label">To Date <span className="text-danger">*</span></label>
//                                 <div className="cal-icon"><input className="form-control datetimepicker" type="text" value="28/08/2021"/></div>
//                              </div>
//                           </div>
//                           <div className="col-sm-6">
//                              <div className="form-group">
//                                 <label className="col-form-label">Start Time <span className="text-danger">*</span></label>
//                                 <div className="colo-icon"><input className="form-control datetimepicker3" type="text" value="07:00"/></div>
//                              </div>
//                           </div>
//                           <div className="col-sm-6">
//                              <div className="form-group">
//                                 <label className="col-form-label">End Time <span className="text-danger">*</span></label>
//                                 <div className="colo-icon"><input className="form-control datetimepicker3" type="text" value="15:00"/></div>
//                              </div>
//                           </div>
//                           <div className="col-sm-6">
//                              <div className="form-group">
//                                 <label className="col-form-label">Repeat Options <span className="text-danger">*</span></label>
//                                 <select className="select">
//                                    <option value="">Select Frequency</option>
//                                    <option value="1" selected>Daily</option>
//                                    <option value="2">Weekly</option>
//                                    <option value="3">Mon-Fri</option>
//                                    <option value="4">Mon-Thu</option>
//                                 </select>
//                              </div>
//                           </div>
//                           <div className="col-sm-6">
//                              <div className="form-group">
//                                 <label className="col-form-label">Break Rule <span className="text-danger">*</span></label>
//                                 <select className="select">
//                                    <option value="">Select</option>
//                                    <option value="1" selected>London Rule</option>
//                                    <option value="2">Act 193</option>
//                                 </select>
//                              </div>
//                           </div>
//                           <div className="col-sm-12">
//                                 <div className="form-group">
//                                    <label>Note <span className="text-danger">*</span></label>
//                                    <textarea className="form-control" rows="4"></textarea>
//                                 </div>
//                              </div>
//                           </div>
//                            </div>
//                            </div>
                           
//                            <div className="contact-cat col-sm-4 mifrmul">
//                                          <div className="roles-menu">
//                                          <input type="text" className="form-control mt-1 mb-1" placeholder="Search Here"/>
//                                          <h4 className="mt-2">Who's Working</h4>
//                                             <ul>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" checked/> Peter Khan</label></div></li>
//                                             </ul>
//                                             <h4 className="mt-2">Available</h4>
                                            
//                                             <ul className="milocmn">
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                                <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//                                             </ul>
//                                          </div>
//                                       </div>
                          
                       
//                        <div className="submit-section col-12 mt-1">
//                           <button className="btn btn-info submit-btn">Save</button>
//                           <button className="btn btn-danger ml-auto submit-btn">Delete</button>
//                        </div>
//                     </form>
//                  </div>
//               </div>
//            </div>
//         </div> */}
//         {/* </div> */}
//     </>
// )