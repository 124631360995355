import axios from "axios";
import {
    MAIL_REQUEST,
    MAIL_SUCCESS,
    MAIL_FAIL,
    MAIL_BY_ID_REQUEST,
    MAIL_BY_ID_SUCCESS,
    MAIL_BY_ID_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER MAIL
export const getMailByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: MAIL_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/mail/getMailByUserId`, obj);
        localStorage.setItem("mailData", JSON.stringify(data));
        dispatch({
            type: MAIL_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MAIL_FAIL,
            payload: err,
        });
    }
};

// USER MAIL
export const getMailByMailId = (obj) => async (dispatch) => {
    dispatch({
        type: MAIL_BY_ID_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/mail/getMailByMailId`, obj);
        // localStorage.setItem("mailData", JSON.stringify(data));
        dispatch({
            type: MAIL_BY_ID_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MAIL_BY_ID_FAIL,
            payload: err,
        });
    }
};

