import React from 'react'
import { NavLink } from 'react-router-dom'
import { shiftPageData } from "../../../actions/shiftActions"
import { useSelector, useDispatch } from 'react-redux'

const EmployeeScheduleMonthly = ({monthdate}) => {
    const data = JSON.parse(window.localStorage.getItem('weeklyShift'))
    const auth = useSelector((state) => state.auth);
    let arr = []
    let temp;
    for (let i=0; i<monthdate.length; i++){
        temp = parseInt(monthdate[i].date)
        data.forEach(e => {
            if (e.dateDD === temp){
                arr[i] = e
            }
            else {
                arr[i] = {}
            }
        });
    }
   return (
      <>
         <div className="row miordr2">
            <div className="col-md-12">
               <div className="table-responsive misftsch misftschmn">
                  <table className="table table-striped custom-table datatable">
                     <thead>
                        <tr>
                           <th>Scheduled Shift</th>
                            {
                                monthdate.map((current, i) =>{
                                    return (
                                        <th>{current.day}<br /> {current.date}</th>
                                    )
                                })
                            }
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>
                              <h2 className="table-avatar">
                                    <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
                                    <NavLink to="/profile">{auth.user.firstName} {auth.user.lastName} <span>{auth.user.jobTitle}</span></NavLink>
                              </h2>
                           </td>
                           {
                                arr.map((current, i) =>{
                                    let shiftType;
                                    let shiftTypeCss = "miclsun";
                                    let shiftTypeIcon = "fa fa-sun-o";
                                    let empty = true
                                    let temp;
                                    let start, end;
                                    let stAmPm = "am";
                                    let enAmPm = "am";
                                    let date;
                                    if (Object.keys(current).length != 0){
                                        let stStart = parseInt(current.startTime.substring(11, 13))
                                        let stStartMin = parseInt(current.startTime.substring(14, 16))
                                        let enEnd = parseInt(current.endTime.substring(11, 13))
                                        let enEndMin = parseInt(current.endTime.substring(14, 16))
                                        if (stStartMin < 10) {
                                            stStartMin = "0"+stStartMin
                                        }
                                        if (enEndMin < 10){
                                            enEndMin = "0"+enEndMin
                                        }
                                        if (stStart === 0){
                                            stStart = 12
                                        }
                                        if (enEnd === 0){
                                            enEnd = 12
                                        }
                                        if (stStart > 12){
                                            stStart = stStart - 12
                                            stAmPm = "pm"
                                        }
                                        if (enEnd > 12){
                                            enEnd = enEnd - 12
                                            enAmPm = "pm"
                                        }
                                        if (stStart >= 6 && stAmPm == "am"){
                                            shiftType = "morning"
                                            shiftTypeCss="miclsun" 
                                            shiftTypeIcon = "fa fa-sun-o"
                                        }
                                        else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm"){
                                            shiftType = "evening"
                                            shiftTypeCss="miclscl"
                                            shiftTypeIcon = "fa fa-cloud" 
                                        }
                                        else if (stStart >= 8 && stAmPm == "pm"){
                                            shiftType = "night"
                                            shiftTypeCss="miclsmn"
                                            shiftTypeIcon = "fa fa-moon-o"
                                        }
                                        else if (stStart >= 0 && stAmPm == "am"){
                                            shiftType = "night"
                                            shiftTypeCss="miclsmn"
                                            shiftTypeIcon = "fa fa-moon-o"
                                        }
                                        start = stStart + ":" + stStartMin + " " + stAmPm 
                                        end = enEnd + ":" + enEndMin + " " + enAmPm;
                                    }
                                    
                                    return (
                                        <>
                                            {
                                                Object.keys(current).length != 0
                                                    ?    
                                                    <td style={{minWidth: "6rem"}} key={i}>
                                                        <div className={`user-add-shedule-list ${shiftTypeCss}`} style={{width: "100%"}}>
                                                            <h2>
                                                                <NavLink to="/location-view">
                                                                    <span className="username-info "><i className={shiftTypeIcon}></i><br />{current.location.name}<br />{start}<br />{end}</span>
                                                                </NavLink>
                                                            </h2>
                                                        </div>
                                                    </td>
                                                    :
                                                    <td style={{minWidth: "6rem"}} key={i}>
                                                        <div className="user-add-shedule-list">
                                                        <h2>
                                                            <NavLink to="/location-view">
                                                                
                                                            </NavLink>
                                                        </h2>
                                                    </div>
                                                </td> 
                                            }    
                                        </>
                                    )
                                })
                            }
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </>
   )
}

export default EmployeeScheduleMonthly


// return (
//     <>
//        <div className="row miordr2">
//           <div className="col-md-12">
//              <div className="table-responsive misftsch misftschmn">
//                 <table className="table table-striped custom-table datatable">
//                    <thead>
//                       <tr>
//                          <th>Scheduled Shift</th>
//                          <th>Sat<br />Aug<br />21</th>
//                          <th>Sun<br />Aug<br />22</th>
//                          <th>Mon<br />Aug<br />23</th>
//                          <th>Tue<br />Aug<br />24</th>
//                          <th>Wed<br />Aug<br />25</th>
//                          <th>Thu<br />Aug<br />26</th>
//                          <th>Fri<br />Aug<br />27</th>
//                          <th>Sat<br />Aug<br />28</th>
//                          <th>Sun<br />Aug<br />29</th>
//                          <th>Mon<br />Aug<br />30</th>
//                          <th>Tue<br />Aug<br />31</th>
//                          <th>Wed<br />Sep<br />1</th>
//                          <th>Thu<br />Sep<br />2</th>
//                          <th>Fri<br />Sep<br />3</th>
//                          <th>Sat<br />Sep<br />4</th>
//                          <th>Sun<br />Sep<br />5</th>
//                          <th>Mon<br />Sep<br />6</th>
//                          <th>Tue<br />Sep<br />7</th>
//                          <th>Wed<br />Sep<br />8</th>
//                          <th>Thu<br />Sep<br />9</th>
//                          <th>Fri<br />Sep<br />10</th>
//                          <th>Sat<br />Sep<br />11</th>
//                          <th>Sun<br />Sep<br />12</th>
//                          <th>Mon<br />Sep<br />13</th>
//                          <th>Tue<br />Sep<br />14</th>
//                          <th>Wed<br />Sep<br />15</th>
//                          <th>Thu<br />Sep<br />16</th>
//                          <th>Fri<br />Sep<br />17</th>
//                       </tr>
//                    </thead>
//                    <tbody>
//                       <tr>
//                          <td>
//                             <h2 className="table-avatar">
//                                <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                             </h2>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>



//                       </tr>


//                       <tr>
//                          <td>
//                             <h2 className="table-avatar">
//                                <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                             </h2>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                       </tr>


//                       <tr>
//                          <td>
//                             <h2 className="table-avatar">
//                                <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                             </h2>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                       </tr>

//                       <tr>
//                          <td>
//                             <h2 className="table-avatar">
//                                <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                             </h2>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                       </tr>




//                       <tr>
//                          <td>
//                             <h2 className="table-avatar">
//                                <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
//                                <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
//                             </h2>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsmn">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-moon-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclsun">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-sun-o"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list miclscl">
//                                <h2>
//                                   <NavLink to="/location-view">
//                                      <span className="username-info "><i className="fa fa-cloud"></i><br />66West<br />6:30 am<br />9:30 pm</span>

//                                   </NavLink>
//                                </h2>
//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                          <td>
//                             <div className="user-add-shedule-list">

//                             </div>
//                          </td>
//                       </tr>



//                    </tbody>
//                 </table>
//              </div>
//           </div>
//        </div>

//        {/* <div id="add_schedule" className="modal custom-modal fade" role="dialog">
//                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
//                   <div className="modal-content">
//                      <div className="modal-header">
//                         <h5 className="modal-title">Add Shifts</h5>
//                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                         <span aria-hidden="true">&times;</span>
//                         </button>
//                      </div>
//                      <div className="modal-body">
//                         <form>
//                         <h4>Create Shift on 22-August</h4>
//                            <div className="row">
//                               <div className="col-sm-5">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Start Time <span className="text-danger">*</span></label>
//                                     <div className="colo-icon"><input className="form-control datetimepicker3" type="text"></div>
//                                  </div>
//                               </div>
//                               <div className="col-sm-5">
//                                  <div className="form-group">
//                                     <label className="col-form-label">End Time <span className="text-danger">*</span></label>
//                                     <div className="colo-icon"><input className="form-control datetimepicker3" type="text"></div>
//                                  </div>
//                               </div>
//                               <div className="col-sm-2">
//                                   <h4 className="mihour">8.5h</h4>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Location <span className="text-danger">*</span></label>
//                                     <select className="select">
//                                        <option value="">Select</option>
//                                        <option value="1">Armed - 66 west</option>
//                                        <option value="2">Officer - 66 west</option>
//                                        <option value="3">Armed - Paul Vista</option>
//                                        <option value="4">Officer - Paul Vista</option>
//                                     </select>
//                                  </div>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Break Rule <span className="text-danger">*</span></label>
//                                     <select className="select">
//                                        <option value="">Select</option>
//                                        <option value="1">London Rule</option>
//                                        <option value="2">Act 193</option>
//                                     </select>
//                                  </div>
//                               </div>
                              
//                               <div className="col-sm-12 mb-3">
//                                  <div className="submit-section mt-1">
//                               <button className="btn btn-info submit-btn">Create 18:00-03:00 Shift</button>
//                            </div>
//                               </div>
//                               </div>
                              
//                               <h4>Or Create Shift from template</h4>
//                               <div className="row">
//                               <div className="col-sm-12">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Shift Template <span className="text-danger">*</span></label>
//                                     <select className="select">
//                                        <option value="">Select</option>
//                                        <option value="1">18:00-03:00 Armed - 66 west</option>
//                                        <option value="2">18:00-03:00 Officer - 66 west</option>
//                                        <option value="3">18:00-03:00 Armed - Paul Vista</option>
//                                        <option value="4">18:00-03:00 Officer - Paul Vista</option>
//                                     </select>
//                                  </div>
//                               </div>
//                               <div className="col-sm-12">
//                                  <div className="submit-section mt-1">
//                               <button className="btn btn-info submit-btn">Select 18:00-03:00 Shift</button>
//                            </div>
//                                </div></div>
//                         </form>
//                      </div>
//                   </div>
//                </div>
//             </div>
//             <div id="edit_schedule" className="modal custom-modal fade" role="dialog">
//                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
//                   <div className="modal-content">
//                      <div className="modal-header">
//                         <h5 className="modal-title">Edit Schedule</h5>
//                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                         <span aria-hidden="true">&times;</span>
//                         </button>
//                      </div>
//                      <div className="modal-body">
//                         <form>
//                            <div className="row">
//                            <div className="col-sm-8">
//                            <div className="row">
//                            <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">From Date <span className="text-danger">*</span></label>
//                                     <div className="cal-icon"><input className="form-control datetimepicker" type="text" value="26/08/2021"></div>
//                                  </div>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">To Date <span className="text-danger">*</span></label>
//                                     <div className="cal-icon"><input className="form-control datetimepicker" type="text" value="28/08/2021"></div>
//                                  </div>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Start Time <span className="text-danger">*</span></label>
//                                     <div className="colo-icon"><input className="form-control datetimepicker3" type="text" value="07:00"></div>
//                                  </div>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">End Time <span className="text-danger">*</span></label>
//                                     <div className="colo-icon"><input className="form-control datetimepicker3" type="text" value="15:00"></div>
//                                  </div>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Repeat Options <span className="text-danger">*</span></label>
//                                     <select className="select">
//                                        <option value="">Select Frequency</option>
//                                        <option value="1" selected>Daily</option>
//                                        <option value="2">Weekly</option>
//                                        <option value="3">Mon-Fri</option>
//                                        <option value="4">Mon-Thu</option>
//                                     </select>
//                                  </div>
//                               </div>
//                               <div className="col-sm-6">
//                                  <div className="form-group">
//                                     <label className="col-form-label">Break Rule <span className="text-danger">*</span></label>
//                                     <select className="select">
//                                        <option value="">Select</option>
//                                        <option value="1" selected>London Rule</option>
//                                        <option value="2">Act 193</option>
//                                     </select>
//                                  </div>
//                               </div>
//                               <div className="col-sm-12">
//                                     <div className="form-group">
//                                        <label>Note <span className="text-danger">*</span></label>
//                                        <textarea className="form-control" rows="4"></textarea>
//                                     </div>
//                                  </div>
//                               </div>
//                                </div>
                               
//                                <div className="contact-cat col-sm-4 mifrmul">
//                                              <div className="roles-menu">
//                                              <input type="text" className="form-control mt-1 mb-1" placeholder="Search Here">
//                                              <h4 className="mt-2">Who's Working</h4>
//                                                 <ul>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" checked> Peter Khan</label></div></li>
//                                                 </ul>
//                                                 <h4 className="mt-2">Available</h4>
                                                
//                                                 <ul className="milocmn">
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                    <li><div className="checkbox"><label><input type="checkbox" name="checkbox" > Peter Khan</label></div></li>
//                                                 </ul>
//                                              </div>
//                                           </div>
                              
                           
//                            <div className="submit-section col-12 mt-1">
//                               <button className="btn btn-info submit-btn">Save</button>
//                               <button className="btn btn-danger ml-auto submit-btn">Delete</button>
//                            </div>
//                         </form>
//                      </div>
//                   </div>
//                </div>
//             </div> */}
//     </>
//  )