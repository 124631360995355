import React from 'react'
import AttendanceOverall from '../../components/employeeComponents/employeeAttendanceComponents/AttendanceOverall'
import AttendanceSearch from '../../components/employeeComponents/employeeAttendanceComponents/AttendanceSearch'
import StatsCard from '../../components/employeeComponents/employeeAttendanceComponents/StatsCard'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeAttendance = () => {
  return (
    <>
      <EmployeeHeader />
      <EmployeeSidebar />
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeTitle title="Attendance" attendance />
            <StatsCard />
            <AttendanceSearch />
            <AttendanceOverall />
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeAttendance