import axios from "axios";
import {
    HOLIDAY_REQUEST,
    HOLIDAY_SUCCESS,
    HOLIDAY_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY HOLIDAY
export const companyHolidays = (obj) => async (dispatch) => {
    dispatch({
        type: HOLIDAY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/holiday/companyHolidays`, obj);
        localStorage.setItem("holiday", JSON.stringify(data));
        dispatch({
            type: HOLIDAY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: HOLIDAY_FAIL,
            payload: err,
        });
    }
};

