import React from 'react'
import { NavLink } from 'react-router-dom'

const AttendanceSearch = () => {
    return (
        <>
            <div className="row filter-row">
                <div className="col-sm-3">
                    <div className="form-group form-focus">
                        <div className="cal-icon">
                            <input type="text" className="form-control floating datetimepicker" />
                        </div>
                        <label className="focus-label">Date</label>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group form-focus select-focus">
                        <select className="select form-control">
                        {/* <select className="select floating"> */}
                            <option>-</option>
                            <option>Jan</option>
                            <option>Feb</option>
                            <option>Mar</option>
                            <option>Apr</option>
                            <option>May</option>
                            <option>Jun</option>
                            <option>Jul</option>
                            <option>Aug</option>
                            <option>Sep</option>
                            <option>Oct</option>
                            <option>Nov</option>
                            <option>Dec</option>
                        </select>
                        <label className="focus-label">Select Month</label>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group form-focus select-focus">
                        <select className="select form-control">
                        {/* <select className="select floating"> */}
                            <option>-</option>
                            <option>2021</option>
                            <option>2018</option>
                            <option>2017</option>
                            <option>2016</option>
                            <option>2015</option>
                        </select>
                        <label className="focus-label">Select Year</label>
                    </div>
                </div>
                <div className="col-sm-3">
                    <NavLink to="#" className="btn btn-success btn-block"> Search </NavLink>
                </div>
            </div>
        </>
    )
}

export default AttendanceSearch