import {
    ANNIVARSARY_REQUEST,
    ANNIVARSARY_SUCCESS,
    ANNIVARSARY_FAIL,
} from "../constants"

export const userReducer = (
    state = {
        user: {
            annivarsary: null
        }
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case ANNIVARSARY_REQUEST : 
            return {
                ...state,
            };
        case ANNIVARSARY_SUCCESS :
            return {
                ...state,
                annivarsary: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case ANNIVARSARY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}