import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector  } from 'react-redux'
// import { userSelector } from '../../app/features/loginSlice';
import moment from 'moment'


const ProfileCover = () => {
    const auth = useSelector((state) => state.auth); 

    // const { isFetching, isSuccess, isError, errorMessage, firstName, email } = useSelector(
    //     userSelector
    //   );

    return (
        <>
            <div className="card mb-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-view">
                                <div className="profile-img-wrap">
                                    <div className="profile-img">
                                        <NavLink to="#"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
                                    </div>
                                </div>
                                <div className="profile-basic">
                                {
                                    auth.user &&
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile-info-left">
                                                <h4 className="user-name m-t-0 mb-0"><strong>Full Name:</strong> {auth.user.firstName}, {auth.user.lastName}</h4>
                                                <h6 className="staff-id"><strong>User Name:</strong> stephaniepatterson</h6>
                                                <div className="staff-id"><strong>Employee ID :</strong> {auth.user.employeeId} </div>
                                                <div className="staff-id"><strong>Wage:</strong> ${auth.user.hourlyWage} per hour </div>
                                                <div className="staff-id"><strong>Joining Date :</strong> {moment(auth.user.startingDate).format("Do MMMM YYYY")}</div>
                                                <div className="staff-msg"><NavLink className="btn btn-custom" to="/inbox">Send Message</NavLink></div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <ul className="personal-info">
                                                <li>
                                                    <div className="title">Phone:</div>
                                                    <div className="text"><NavLink to="/dashboard"> {auth.user.phone} </NavLink> <span className="text-success miphcn">  Confirmed <i className="fa fa-check-circle text-success"></i></span></div>
                                                </li>
                                                <li>    
                                             <div className="title">Email:</div>
                                             <div className="text"><a href=""><span> {auth.user.email} </span></a></div>
                                          </li>
                                          {/* <li>
                                             <div className="title">Joining Date:</div>
                                             <div className="text">24th July 2021</div>
                                          </li> */}
                                                <li>
                                                    <div className="title">Address:</div>
                                                    <div className="text">{auth.user.address}, {auth.user.state}, {auth.user.country}</div>
                                                </li>
                                                <li>
                                                    <div className="title">Positions:</div>
                                                    <div className="text milocpmn">
                                                        <div className="miprflps">
                                                            <NavLink to="#" className="avatar">
                                                                <div className="ylwclr"></div>
                                                            </NavLink>
                                                            <NavLink to=""> {auth.user.jobTitle} </NavLink>
                                                        </div>
                                                        {/* <div className="miprflps">
                                                            <NavLink to="#" className="avatar">
                                                                <div className="ylwclr"></div>
                                                            </NavLink>
                                                            <NavLink to="">Un-Armed Officer</NavLink>
                                                        </div>
                                                        <div className="miprflps">
                                                            <NavLink to="#" className="avatar">
                                                                <div className="ylwclr"></div>
                                                            </NavLink>
                                                            <NavLink to="">Un-Armed Officer</NavLink>
                                                        </div>
                                                        <div className="miprflps">
                                                            <NavLink to="#" className="avatar">
                                                                <div className="ylwclr"></div>
                                                            </NavLink>
                                                            <NavLink to="">Un-Armed Officer</NavLink>
                                                        </div>
                                                        <div className="miprflps">
                                                            <NavLink to="#" className="avatar">
                                                                <div className="ylwclr"></div>
                                                            </NavLink>
                                                            <NavLink to="">Un-Armed Officer</NavLink>
                                                        </div> */}
                                                        {/* <div className="miprflps text-info">
                                                            <NavLink to="#"><i className="fa fa-plus"></i> 27 More</NavLink>
                                                        </div> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="pro-edit"><NavLink data-target="#profile_info" data-toggle="modal" className="edit-icon" to="#"><i className="fa fa-pencil"></i></NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileCover