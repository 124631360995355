import React from 'react'
import { NavLink } from 'react-router-dom'

const TimesheetTable = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    <th>Date</th>
                                    <th>Locations</th>
                                    <th className="text-center">Assigned Hours</th>
                                    <th className="text-center">Hours</th>
                                    <th className="d-none d-sm-table-cell">Description</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
                                            <NavLink to="/profile">John Doe <span>Un-Armed Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Office Management</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">7</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-09.jpg" /></NavLink>
                                            <NavLink to="/profile">Richard Miles <span>Armed Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Locations Management</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-10.jpg" /></NavLink>
                                            <NavLink to="/profile">John Smith <span>Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Video Calling App</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-05.jpg" /></NavLink>
                                            <NavLink to="/profile">Mike Litorus <span>Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Hospital Administration</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-11.jpg" /></NavLink>
                                            <NavLink to="/profile">Wilmer Deluna <span>Team Supervisior</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Office Management</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">7</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-12.jpg" /></NavLink>
                                            <NavLink to="/profile">Jeffrey Warden <span>Armed Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Locations Management</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-13.jpg" /></NavLink>
                                            <NavLink to="/profile">Bernardo Galaviz <span>Armed Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Video Calling App</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-01.jpg" /></NavLink>
                                            <NavLink to="/profile">Lesley Grauer <span>Team Supervisior</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Hospital Administration</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-16.jpg" /></NavLink>
                                            <NavLink to="/profile">Jeffery Lalor <span>Team Supervisior</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Ware house developement</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">9</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-04.jpg" /></NavLink>
                                            <NavLink to="/profile">Loren Gatlin <span>Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Office Management</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-03.jpg" /></NavLink>
                                            <NavLink to="/profile">Tarah Shropshire <span>Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Locations Management</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-08.jpg" /></NavLink>
                                            <NavLink to="/profile">Catherine Manseau <span>Officer</span></NavLink>
                                        </h2>
                                    </td>
                                    <td>8 Mar 2021</td>
                                    <td>
                                        <h2>Video Calling App</h2>
                                    </td>
                                    <td className="text-center">20</td>
                                    <td className="text-center">12</td>
                                    <td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                                <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</NavLink>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimesheetTable