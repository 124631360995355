import React from 'react'
import { NavLink } from 'react-router-dom'

const FileManagerMain = () => {
    return (
        <>
            <div className="file-cont-wrap">
                <div className="file-cont-inner">
                    <div className="file-cont-header">
                        <div className="file-options "> 
                            {/* abhi set krna hai */}
                            {/* <NavLink to="javascript:void(0)" id="file_sidebar_toggle" className="file-sidebar-toggle">
                                <i></i>
                            </NavLink> */}
                        </div>
                        <span>File Manager</span>
                        <div className="file-options">
                            <span className="btn-file"><input type="file" className="upload"/><i className="fa fa-upload"></i></span>
                        </div>
                    </div>
                    <div className="file-content">
                        <form className="file-search">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <i className="fa fa-search"></i>
                                </div>
                                <input type="text" className="form-control" placeholder="Search"/>
                            </div>
                        </form>
                        <div className="file-body">
                            <div className="file-scroll">
                                <div className="file-content-inner">
                                    <h4>Recent Files</h4>
                                    <div className="row row-sm">
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-pdf-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Sample.pdf</NavLink></h6>
                                                    <span>10.45kb</span>
                                                </div>
                                                <div className="card-footer">
                                                    <span className="d-none d-sm-inline">Last Modified: </span>1 min ago
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-word-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Document.docx</NavLink></h6>
                                                    <span>22.67kb</span>
                                                </div>
                                                <div className="card-footer">
                                                    <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-image-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">icon.png</NavLink></h6>
                                                    <span>12.47kb</span>
                                                </div>
                                                <div className="card-footer">
                                                    <span className="d-none d-sm-inline">Last Modified: </span>1 hour ago
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-excel-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Users.xls</NavLink></h6>
                                                    <span>35.11kb</span>
                                                </div>
                                                <div className="card-footer">23 Jul 6:30 PM</div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4>Files</h4>
                                    <div className="row row-sm">
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-word-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Updates.docx</NavLink></h6>
                                                    <span>12mb</span>
                                                </div>
                                                <div className="card-footer">9 Aug 1:17 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-powerpoint-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Vision.ppt</NavLink></h6>
                                                    <span>72.50kb</span>
                                                </div>
                                                <div className="card-footer">6 Aug 11:42 AM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-audio-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Voice.mp3</NavLink></h6>
                                                    <span>2.17mb</span>
                                                </div>
                                                <div className="card-footer">
                                                    <span className="d-none d-sm-inline">Last Modified: </span>30 Jul 9:00 PM
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-pdf-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Tutorials.pdf</NavLink></h6>
                                                    <span>8.2mb</span>
                                                </div>
                                                <div className="card-footer">21 Jul 10:45 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-excel-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Tasks.xls</NavLink></h6>
                                                    <span>92.82kb</span>
                                                </div>
                                                <div className="card-footer">16 Jun 4:59 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-image-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Page.psd</NavLink></h6>
                                                    <span>118.71mb</span>
                                                </div>
                                                <div className="card-footer">14 Jun 9:00 AM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-text-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">License.txt</NavLink></h6>
                                                    <span>18.7kb</span>
                                                </div>
                                                <div className="card-footer">5 May 8:21 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-word-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Expenses.docx</NavLink></h6>
                                                    <span>66.2kb</span>
                                                </div>
                                                <div className="card-footer">24 Apr 7:50 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-audio-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Music.mp3</NavLink></h6>
                                                    <span>3.6mb</span>
                                                </div>
                                                <div className="card-footer">13 Mar 2:00 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-text-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Installation.txt</NavLink></h6>
                                                    <span>43.9kb</span>
                                                </div>
                                                <div className="card-footer">26 Feb 5:01 PM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-video-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">Workflow.mp4</NavLink></h6>
                                                    <span>47.65mb</span>
                                                </div>
                                                <div className="card-footer">3 Jan 11:35 AM</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                            <div className="card card-file">
                                                <div className="dropdown-file">
                                                    <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                        <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                    </div>
                                                </div>
                                                <div className="card-file-thumb">
                                                    <i className="fa fa-file-code-o"></i>
                                                </div>
                                                <div className="card-body">
                                                    <h6><NavLink to="">index.html</NavLink></h6>
                                                    <span>23.7kb</span>
                                                </div>
                                                <div className="card-footer">1 Jan 8:55 AM</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FileManagerMain