import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getAllTraining } from "../../../actions/trainingActions"
import { useSelector, useDispatch } from 'react-redux'


const TrainingTable = () => {
    const auth = useSelector((state) => state.auth);
    const training = useSelector((state) => state.training);
    const dispatch = useDispatch()
    const trainingData = async (e) => {
        dispatch(getAllTraining({companyId: auth.user.companyId}))
    }
    useEffect(() => {
        trainingData()
    },[])
    let i=0
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th style={{ width: "30px" }}>#</th>
                                    <th>Name</th>
                                    <th>Training Type</th>
                                    <th>Employee</th>
                                    <th>Time Duration</th>
                                    <th>Description </th>
                                    <th>Status </th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    training.training &&
                                        training.training.length > 0 &&
                                            training.training.map((current, i) => {
                                                if (current.status === "active") {
                                                    return(
                                                        <tr key={i}>
                                                            <td>{++i}</td>
                                                            <td>{current.trainingName}</td>
                                                            <td>{current.trainingType}</td>
                                                            <td>
                                                                <ul className="team-members">
                                                                    <li>
                                                                        <NavLink to="#" title="Bernardo Galaviz" data-toggle="tooltip"><img alt="" src="assets/img/avatar-21.jpg" /></NavLink>
                                                                    </li>
                                                                    <li>
                                                                        <NavLink to="#" title="Richard Miles" data-toggle="tooltip"><img alt="" src="assets/img/avatar-21.jpg" /></NavLink>
                                                                    </li>
                                                                    <li className="dropdown avatar-dropdown">
                                                                        <NavLink to="#" className="all-users dropdown-toggle" data-toggle="dropdown" aria-expanded="false">+15</NavLink>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <div className="avatar-group">
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                                <NavLink className="avatar avatar-xs" to="#">
                                                                                    <img alt="" src="assets/img/avatar-21.jpg" />
                                                                                </NavLink>
                                                                            </div>
                                                                            {/* <div className="avatar-pagination">
                                                                                <ul className="pagination">
                                                                                    <li className="page-item">
                                                                                        <NavLink className="page-link" to="#" aria-label="Previous">
                                                                                            <span aria-hidden="true">«</span>
                                                                                            <span className="sr-only">Previous</span>
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className="page-item"><NavLink className="page-link" to="#">1</NavLink></li>
                                                                                    <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li>
                                                                                    <li className="page-item">
                                                                                        <NavLink className="page-link" to="#" aria-label="Next">
                                                                                            <span aria-hidden="true">»</span>
                                                                                            <span className="sr-only">Next</span>
                                                                                        </NavLink>
                                                                                    </li>
                                                                                </ul>
                                                                            </div> */}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                            <td>{current.startDate.substring(0,10)} - {current.endDate.substring(0,10)}</td>
                                                            <td>{current.description}</td>
                                                            <td>
                                                                <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false"> Active <i className="fa fa-circle-o text-success"></i> </NavLink>
                                                            </td>
                                                            <td className="text-right miinl">
                                                                <div className="dropdown dropdown-action">
                                                                    <NavLink
                                                                        //   className="dropdown-item" 
                                                                        to="#"
                                                                    ><i className="fa fa-map-marker m-r-5"></i></NavLink>
                                                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                                                    <NavLink
                                                                        //   className="dropdown-item" 
                                                                        to="#"
                                                                    ><i className="fa fa-pencil m-r-5"></i></NavLink>
                                                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                                                    <NavLink
                                                                        //   className="dropdown-item" 
                                                                        to="#"
                                                                    ><i className="fa fa-trash-o m-r-5"></i></NavLink>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingTable














// return (
//     <>
//         <div className="row">
//             <div className="col-md-12">
//                 <div className="table-responsive">
//                     <table className="table table-striped custom-table mb-0 datatable">
//                         <thead>
//                             <tr>
//                                 <th style={{ width: "30px" }}>#</th>
//                                 <th>Name</th>
//                                 <th>Training Type</th>
//                                 <th>Employee</th>
//                                 <th>Time Duration</th>
//                                 <th>Description </th>
//                                 <th>Status </th>
//                                 <th className="text-right">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>{++i}</td>
//                                 <td>London Gate</td>
//                                 <td>Armed Training</td>
//                                 <td>
//                                     <ul className="team-members">
//                                         <li>
//                                             <NavLink to="#" title="Bernardo Galaviz" data-toggle="tooltip"><img alt="" src="assets/img/avatar-21.jpg" /></NavLink>
//                                         </li>
//                                         <li>
//                                             <NavLink to="#" title="Richard Miles" data-toggle="tooltip"><img alt="" src="assets/img/avatar-21.jpg" /></NavLink>
//                                         </li>
//                                         <li className="dropdown avatar-dropdown">
//                                             <NavLink to="#" className="all-users dropdown-toggle" data-toggle="dropdown" aria-expanded="false">+15</NavLink>
//                                             <div className="dropdown-menu dropdown-menu-right">
//                                                 <div className="avatar-group">
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                 </div>
//                                                 <div className="avatar-pagination">
//                                                     <ul className="pagination">
//                                                         <li className="page-item">
//                                                             <NavLink className="page-link" to="#" aria-label="Previous">
//                                                                 <span aria-hidden="true">«</span>
//                                                                 <span className="sr-only">Previous</span>
//                                                             </NavLink>
//                                                         </li>
//                                                         <li className="page-item"><NavLink className="page-link" to="#">1</NavLink></li>
//                                                         <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li>
//                                                         <li className="page-item">
//                                                             <NavLink className="page-link" to="#" aria-label="Next">
//                                                                 <span aria-hidden="true">»</span>
//                                                                 <span className="sr-only">Next</span>
//                                                             </NavLink>
//                                                         </li>
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     </ul>
//                                 </td>
//                                 <td>7 May 2019 - 10 May 2019</td>
//                                 <td>Lorem ipsum dollar</td>
//                                 <td>
//                                     <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false"> Active <i className="fa fa-circle-o text-success"></i> </NavLink>
//                                 </td>
//                                 <td className="text-right miinl">
//                                     <div className="dropdown dropdown-action">
//                                         <NavLink
//                                             //   className="dropdown-item" 
//                                             to="#"
//                                         ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                         <span>&nbsp;&nbsp;&nbsp;</span>
//                                         <NavLink
//                                             //   className="dropdown-item" 
//                                             to="#"
//                                         ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                         <span>&nbsp;&nbsp;&nbsp;</span>
//                                         <NavLink
//                                             //   className="dropdown-item" 
//                                             to="#"
//                                         ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                     </div>
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td>2</td>
//                                 <td>London Gate</td>
//                                 <td>Armed Training</td>
//                                 <td>
//                                     <ul className="team-members">
//                                         <li>
//                                             <NavLink to="#" title="Bernardo Galaviz" data-toggle="tooltip"><img alt="" src="assets/img/avatar-21.jpg" /></NavLink>
//                                         </li>
//                                         <li>
//                                             <NavLink to="#" title="Richard Miles" data-toggle="tooltip"><img alt="" src="assets/img/avatar-21.jpg" /></NavLink>
//                                         </li>
//                                         <li className="dropdown avatar-dropdown">
//                                             <NavLink to="#" className="all-users dropdown-toggle" data-toggle="dropdown" aria-expanded="false">+15</NavLink>
//                                             <div className="dropdown-menu dropdown-menu-right">
//                                                 <div className="avatar-group">
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                     <NavLink className="avatar avatar-xs" to="#">
//                                                         <img alt="" src="assets/img/avatar-21.jpg" />
//                                                     </NavLink>
//                                                 </div>
//                                                 <div className="avatar-pagination">
//                                                     <ul className="pagination">
//                                                         <li className="page-item">
//                                                             <NavLink className="page-link" to="#" aria-label="Previous">
//                                                                 <span aria-hidden="true">«</span>
//                                                                 <span className="sr-only">Previous</span>
//                                                             </NavLink>
//                                                         </li>
//                                                         <li className="page-item"><NavLink className="page-link" to="#">1</NavLink></li>
//                                                         <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li>
//                                                         <li className="page-item">
//                                                             <NavLink className="page-link" to="#" aria-label="Next">
//                                                                 <span aria-hidden="true">»</span>
//                                                                 <span className="sr-only">Next</span>
//                                                             </NavLink>
//                                                         </li>
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     </ul>
//                                 </td>
//                                 <td>7 May 2019 - 10 May 2019</td>
//                                 <td>Lorem ipsum dollar</td>
//                                 <td>
//                                     <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false"> Active <i className="fa fa-circle-o text-success"></i> </NavLink>
//                                 </td>
//                                 <td className="text-right miinl">
//                                     <div className="dropdown dropdown-action">
//                                         <NavLink
//                                             //   className="dropdown-item" 
//                                             to="#"
//                                         ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                         <span>&nbsp;&nbsp;&nbsp;</span>
//                                         <NavLink
//                                             //   className="dropdown-item" 
//                                             to="#"
//                                         ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                         <span>&nbsp;&nbsp;&nbsp;</span>
//                                         <NavLink
//                                             //   className="dropdown-item" 
//                                             to="#"
//                                         ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                     </div>
//                                 </td>
//                             </tr>


//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     </>
// )