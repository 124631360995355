import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { shiftProfile } from '../../actions/shiftActions'


const Shifts = () => {

   const auth = useSelector((state) => state.auth);
   const shift = useSelector((state) => state.shift);
   

   const dispatch = useDispatch()


   const shiftProfileData = async (e) => {
      const currDate = moment().format()
      const arr = currDate.split("+")
      const newCurrDate = arr[0]+".000+00:00"
      // console.log("shiftProfileData",auth.user.id)
      dispatch(shiftProfile({userId: auth.user.id, date: newCurrDate}))
  }

   useEffect(() => {
      shiftProfileData()
  },[])

  console.log(moment().format().substring(0,10))
  
   return (
      <>
         <div className="row">
            <div className="col-md-6 d-flex">
               <div className="card profile-box flex-fill">
                  <div className="card-body">
                     <h3 className="card-title">Upcoming Shifts</h3>
                     <div className="experience-box miprcrl">
                        <ul className="experience-list ">
                        {
                           shift.upcomingShift &&
                           shift.upcomingShift.length > 0 &&
                           shift.upcomingShift.map((current, i) => {
                                    return (

                           <li className="misftact">
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                 <div>{moment(current.startTime.substring(5, 10)).format("Do-MMMM-YYYY")}</div>
                                                   <NavLink to="/location-view" className="name">{current.location} - <strong>{auth.user.jobTitle}</strong></NavLink>
                                                   <span className="time">{current.startTime.toString().substring(11, 16)} - {current.endTime.substring(11, 16)}</span>
                                                   
                                                   {
                                                      current.startTime.substring(0, 10) === moment().format().substring(0, 10) ?
                                                      <span className="btn add-btn">Ongoing</span>

                                                      :
                                                      <div></div>

                                                   }
                                                
                                                </div>
                                             </div>
                                          </li>
                                       )
                              })}

                           {/* <li className="misftact">
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>27-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pizza La Vista  - <strong>Armed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                    <span className="btn add-btn">Today</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>28-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pizza La Vista  - <strong>Armed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>29-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pizza La Vista  - <strong>Armed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>30-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pacal  - <strong>Unarmed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>31-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pacal  - <strong>Unarmed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>1-September-2021</div>
                                    <NavLink to="/location-view" className="name">Pacal  - <strong>Unarmed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li> */}
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-md-6 d-flex">
               <div className="card profile-box flex-fill">
                  <div className="card-body">
                     <h3 className="card-title">Recent Shifts</h3>
                     <div className="experience-box miprcrl">
                        <ul className="experience-list ">
                        {
                              shift.recentShift &&
                                 shift.recentShift.length > 0 &&
                                    shift.recentShift.map((current, i) => {
                                       return (
                                          <li>
                                             <div className="experience-user">
                                                <div className="before-circle"></div>
                                             </div>
                                             <div className="experience-content">
                                                <div className="timeline-content">
                                                   <div>{moment(current.startTime.substring(5, 10)).format("Do-MMMM-YYYY")}</div>
                                                   <NavLink to="/location-view" className="name">{current.location} - <strong>{auth.user.jobTitle}</strong></NavLink>
                                                   <span className="time">{current.startTime.substring(11, 16)} - {current.endTime.substring(11, 16)}</span>
                                                </div>
                                             </div>
                                          </li>
                                       )
                                    })}

                           {/* <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>21-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pizza La Vista  - <strong>Armed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>20-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pizza La Vista  - <strong>Armed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>19-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pizza La Vista  - <strong>Armed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>18-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pacal  - <strong>Unarmed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>17-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pacal  - <strong>Unarmed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <div className="experience-user">
                                 <div className="before-circle"></div>
                              </div>
                              <div className="experience-content">
                                 <div className="timeline-content">
                                    <div>17-August-2021</div>
                                    <NavLink to="/location-view" className="name">Pacal  - <strong>Unarmed Officer</strong></NavLink>
                                    <span className="time">20:00 - 03:30</span>
                                 </div>
                              </div>
                              
                           </li> */}
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Shifts