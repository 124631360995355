import {
    TRAINING_REQUEST,
    TRAINING_SUCCESS,
    TRAINING_FAIL,
} from "../constants"

export const trainingReducer = (
    state = {
        training: {
            training: null
        }
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case TRAINING_REQUEST : 
            return {
                ...state,
            };
        case TRAINING_SUCCESS :
            return {
                ...state,
                training: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case TRAINING_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}