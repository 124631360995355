import axios from "axios";
import {
    CLOCK_IN_REQUEST,
    CLOCK_IN_SUCCESS,
    CLOCK_IN_FAIL,
    CLOCK_OUT_REQUEST,
    CLOCK_OUT_SUCCESS,
    CLOCK_OUT_FAIL,
    CLOCK_EXIST_REQUEST,
    CLOCK_EXIST_SUCCESS,
    CLOCK_EXIST_FAIL,
    CLOCK_STATUS_REQUEST,
    CLOCK_STATUS_SUCCESS,
    CLOCK_STATUS_FAIL,
    CLOCK_MONTHLY_DATA_REQUEST,
    CLOCK_MONTHLY_DATA_SUCCESS,
    CLOCK_MONTHLY_DATA_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER CLOCK IN
export const clockIn = (obj) => async (dispatch) => {
    dispatch({
        type: CLOCK_IN_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/clock/clockIn`, obj);
        
        if (data !== "exists") {
            localStorage.setItem("clockData", JSON.stringify(data));
            localStorage.setItem("clockInButton", false);
            localStorage.setItem("breakButton", true);
        }
        dispatch({
            type: CLOCK_IN_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: CLOCK_IN_FAIL,
            payload: err,
        });
    }
};

// USER CLOCK OUT
export const clockUpdate = (obj) => async (dispatch) => {
    dispatch({
        type: CLOCK_OUT_REQUEST,
    });
    try {
        const { data, status } = await axios.put(`${SERVERADDRESS}/v1/clock/updateClock`, obj);
            localStorage.setItem("clockData", JSON.stringify(data));
            if (status === 200) {
                if (data.clockedIn === false) {
                    // console.log("first")
                    localStorage.setItem("clockInButton", true);
                    localStorage.setItem("breakButton", null);
                }
                else {
                    // console.log("second")
                    localStorage.setItem("clockInButton", false);
                    localStorage.setItem("breakButton", true);
                }
            }
        dispatch({
            type: CLOCK_OUT_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: CLOCK_OUT_FAIL,
            payload: err,
        });
    }
};

// USER CLOCK CHECK BY DAYIDENTIFIER
export const clockExist = (obj) => async (dispatch) => {
    dispatch({
        type: CLOCK_EXIST_REQUEST,
    });
    try {
        const { data, status } = await axios.post(`${SERVERADDRESS}/v1/clock/getClockByDayIdentifier`, obj);
            if (status === 200) {
                localStorage.setItem("clockData", JSON.stringify(data));
                if (data.clockedIn === false) {
                    localStorage.setItem("clockInButton", true);
                    localStorage.setItem("breakButton", null);
                }
                else {
                    localStorage.setItem("clockInButton", false);
                    localStorage.setItem("breakButton", true);
                }
            }
        dispatch({
            type: CLOCK_EXIST_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        localStorage.removeItem("clockData")
        dispatch({
            type: CLOCK_EXIST_FAIL,
            payload: err,
        });
    }
};

// MONTHLY AND WEEK CLOCK STATUS OF USER

export const monthlyClockStatusById = (obj) => async (dispatch) => {
    dispatch({
        type: CLOCK_STATUS_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/clock/monthlyClockStatusById`, obj);
        localStorage.setItem("clockStatusData", JSON.stringify(data));
        dispatch({
            type: CLOCK_STATUS_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        dispatch({
            type: CLOCK_STATUS_FAIL,
            payload: err,
        });
    }
};

// MONTHLY CLOCK DATA OF USER

export const getClocksByMonthYearAndUserId = (obj) => async (dispatch) => {
    dispatch({
        type: CLOCK_MONTHLY_DATA_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/clock/getClocksByMonthYearAndUserId`, obj);
        localStorage.setItem("clockMonthlyData", JSON.stringify(data));
        dispatch({
            type: CLOCK_MONTHLY_DATA_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        dispatch({
            type: CLOCK_MONTHLY_DATA_FAIL,
            payload: err,
        });
    }
};

