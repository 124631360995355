import React, { useState, useEffect } from 'react'
import '../assets/css/login.css'
// import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserByToken, userLogin } from "../actions/authActions"
// import { loginUser } from '../app/features/loginSlice'
// import { loginUser } from '../app/features/LoginSlice';
import logo from "../assets/img/logo.png"

function Login() {

    const auth = useSelector((state) => state.auth);
    const [ error, setError ] = useState("");
    // const userToken =localStorage.getItem("tokens")
    // ? localStorage.getItem('tokens') : null

    // const saveToken =
    //     localStorage.getItem("tokens") ?
    //         localStorage.getItem("tokens") : null

    // const userToken = JSON.parse(saveToken)
    let navigate = useNavigate()
    const navigateUser = () => {
        navigate('/dashboard')
    }
    // neeche condition agr refresh token majoo nahi toh localtorge.cler()
    // phir navigate to login
    // agr majood hai toh refresh and then navigate to dashboard
    // aur agr nahi hai toh navigate to login
    const dispatch = useDispatch()
    
    const chkUser = async () => {
        await dispatch(getUserByToken(auth.tokens.refresh.token, navigate))
    }
    useEffect(() => {
        if (localStorage.tokens === undefined){
            console.log("empty")
        }
        else {
            chkUser()

        }
        // if(localStorage.tokens !== null){
        // }
            // if()
            // navigate('/dashboard')
    },[])

    const [values, setValues] = useState({
        email: '',
        password: ''
    })
    let role = ["admin", "employee", "superadmin"]

    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const userData = {
        email: values.email,
        password: values.password
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        await dispatch(userLogin(userData, navigateUser))
        // if (userToken) {
            console.log(auth)
            // console.log(!auth.error)
            // if(auth.user !== null && !auth.error && auth.loading === false){
            // navigate('/dashboard')
        // }
            
        //     console.log(userToken)
            // NavLink
        // }
    }
    return (
        <>
            {/* <h1>Image
            </h1> */}
            <img src={logo} style={{ 
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
            }}/>
            <div className='account-box'>
                <div style={{ padding: '30px' }}>
                    <h2 style={{
                        fontSize: '26px',
                        fontWeight: '500',
                        marginBottom: ' 5px',
                        textAlign: 'center'
                    }}>Login</h2>
                    <p className='account-subtitle'>Access to our dashboard</p>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <label>Email Address</label>
                            <div className=' form-group'>
                                <input className="form-control" name="email" type="text"
                                    autoComplete="false" value={values.email}
                                    onChange={handleChange('email')}
                                ></input>
                                <small></small>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label>Password</label>
                                    </div><div className="col-auto">
                                        <a className="text-muted" href="/forgotpassword">Forgot password?</a>
                                    </div></div>
                                <div className="pass-group">
                                    <input type="password" className="form-control" name="password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        autoComplete="false"
                                    />
                                    <span className="fa toggle-password&quot; fa-eye-slash"></span>
                                </div><small></small><small></small>
                            </div>
                            <div className="form-group text-center">
                                <button className="btn btn-primary account-btn" type="submit">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login

// import React, { useState, useEffect } from 'react'
// import '../assets/css/login.css'
// import axios from 'axios'
// import { NavLink, useNavigate } from 'react-router-dom'
// import { Redirect } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux'
// import { loginUser } from '../app/features/loginSlice'
// // import { loginUser } from '../app/features/LoginSlice';
// import logo from "../assets/img/logo.png"
// import { login } from "../slices/auth";
// import { clearMessage } from "../slices/message";

// function Login(props) {
//     const [loading, setLoading] = useState(false);
//   const { isLoggedIn } = useSelector((state) => state.auth);
//   const { message } = useSelector((state) => state.message);
//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(clearMessage());
//   }, [dispatch]);
//   const initialValues = {
//     username: "",
//     password: "",
//   };
//     // const userToken =localStorage.getItem("tokens")
//     // ? localStorage.getItem('tokens') : null

//     // const saveToken =
//     //     localStorage.getItem("tokens") ?
//     //         localStorage.getItem("tokens") : null

//     // const userToken = JSON.parse(saveToken)
    
  
//     // const dispatch = useDispatch()
//     // let navigate = useNavigate()
//     // const [values, setValues] = useState({
//     //     email: '',
//     //     password: ''
//     // })
//     let role = ["admin", "employee", "superadmin"]

//     const handleChange = (fieldName) => (event) => {
//         setValues({
//             ...values, [fieldName]:
//                 event.target.value
//         })
//     }
//     const userData = {
//         email: values.email,
//         password: values.password
//     }
//     const handleSubmit = async (event) => {
//         event.preventDefault()
//         dispatch(loginUser(userData))
//         if (userToken) {
//             navigate('/dashboard')
            
//             console.log(userToken)
//             // NavLink
//         }
//     }
//     return (
//         <>
//             {/* <h1>Image
//             </h1> */}
//             <img src={logo} style={{ 
//                 display: "block",
//                 marginLeft: "auto",
//                 marginRight: "auto",
//             }}/>
//             <div className='account-box'>
//                 <div style={{ padding: '30px' }}>
//                     <h2 style={{
//                         fontSize: '26px',
//                         fontWeight: '500',
//                         marginBottom: ' 5px',
//                         textAlign: 'center'
//                     }}>Login</h2>
//                     <p className='account-subtitle'>Access to our dashboard</p>
//                     <div>
//                         <form onSubmit={handleSubmit}>
//                             <label>Email Address</label>
//                             <div className=' form-group'>
//                                 <input className="form-control" name="email" type="text"
//                                     autoComplete="false" value={values.email}
//                                     onChange={handleChange('email')}
//                                 ></input>
//                                 <small></small>
//                             </div>
//                             <div className="form-group">
//                                 <div className="row">
//                                     <div className="col">
//                                         <label>Password</label>
//                                     </div><div className="col-auto">
//                                         <a className="text-muted" href="/forgotpassword">Forgot password?</a>
//                                     </div></div>
//                                 <div className="pass-group">
//                                     <input type="password" className="form-control" name="password"
//                                         value={values.password}
//                                         onChange={handleChange('password')}
//                                         autoComplete="false"
//                                     />
//                                     <span className="fa toggle-password&quot; fa-eye-slash"></span>
//                                 </div><small></small><small></small>
//                             </div>
//                             <div className="form-group text-center">
//                                 <button className="btn btn-primary account-btn" type="submit">Login</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Login


// import React, { useState, useEffect  } from "react";
// import { useDispatch, useSelector } from "react-redux";
// // import { Redirect } from "react-router-dom"; 
// import { Navigate } from "react-router-dom";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { login } from "../slices/auth";
// import { clearMessage } from "../slices/message";
// const Login = (props) => {
//   const [loading, setLoading] = useState(false);
//   const { isLoggedIn } = useSelector((state) => state.auth);
//   const { message } = useSelector((state) => state.message);
//   // let navigate = useNavigate()
//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(clearMessage());
//   }, [dispatch]);
//   const initialValues = {
//     email: "",
//     password: "",
//   };
//   const validationSchema = Yup.object().shape({
//     email: Yup.string().required("This field is required!"),
//     password: Yup.string().required("This field is required!"),
//   });
//   const handleLogin = (formValue) => {
//     const { email, password } = formValue;
//     setLoading(true);
//     dispatch(login({ email, password }))
//       .unwrap()
//       .then(() => {
//         props.history.push("/dashboard");
//         window.location.reload();
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//   };
//   // if (isLoggedIn) {
//   //       // return <Navl path="/dashboard" element={<EmployeeHome />} />;
//   //       return <Navigate to="/dashboard" />;
//   // }
//   return (
//     <div className="col-md-12 login-form">
//       <div className="card card-container">
//         <img
//           src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
//           alt="profile-img"
//           className="profile-img-card"
//         />
//         <Formik
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           onSubmit={handleLogin}
//         >
//           <Form>
//             <div className="form-group">
//               <label htmlFor="username">Email</label>
//               <Field name="username" type="text" className="form-control" />
//               <ErrorMessage
//                 name="username"
//                 component="div"
//                 className="alert alert-danger"
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="password">Password</label>
//               <Field name="password" type="password" className="form-control" />
//               <ErrorMessage
//                 name="password"
//                 component="div"
//                 className="alert alert-danger"
//               />
//             </div>
//             <div className="form-group">
//               <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
//                 {loading && (
//                   <span className="spinner-border spinner-border-sm"></span>
//                 )}
//                 <span>Login</span>
//               </button>
//             </div>
//           </Form>
//         </Formik>
//       </div>
//       {message && (
//         <div className="form-group">
//           <div className="alert alert-danger" role="alert">
//             {message}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
// export default Login;
