import React from 'react'
import ProfileApproval from './ProfileApproval'
import ProfileLeaves from './ProfileLeaves'
import SharedFiles from './SharedFiles'
import Shifts from './Shifts'

const ProfileMain = () => {
  return (
    <>
      <div className="tab-content">
        <div id="emp_profile" className="pro-overview tab-pane fade show active">
          <Shifts />
          <div className="row">
            <ProfileLeaves />
            {/* <ProfileApproval /> */}
          <SharedFiles />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileMain