import axios from "axios";
import {
    LOCATION_REQUEST,
    LOCATION_SUCCESS,
    LOCATION_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY LOCATION
export const getCompanyLocations = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getAllLocations`, obj);
        localStorage.setItem("location", JSON.stringify(data));
        dispatch({
            type: LOCATION_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LOCATION_FAIL,
            payload: err,
        });
    }
};

// //USER LOGOUT
// export const userLogout = (obj, navigateUser) => async (dispatch) => {
//     try {
//       await axios.post(`${SERVERADDRESS}v1/auth/logout`, obj);
//       localStorage.removeItem("tokens");
//       localStorage.removeItem("user");
//     //   localStorage.removeItem("selectedPackage");
//       dispatch({
//         type: USER_LOGOUT_SUCCESS,
//       });
//       navigateUser()
//     } catch (err) {
//       dispatch({
//         type: USER_LOGOUT_FAIL,
//         payload: "err.response.data.message",
//       });
//     }
// };

// export const getUserByToken = (token, navigate) => async (dispatch) => {
//   navigate('/checkUser')
//   dispatch({
//     type: GET_USER_REQUEST,
    
//   });
//   try {
//     const { data } = await axios.post(`${SERVERADDRESS}v1/auth/refresh-tokens`, {
//       refreshToken: token,
//     });
//     localStorage.setItem("tokens", JSON.stringify(data));
//     dispatch({
//       type: GET_USER_SUCCESS,
//       payload: data,
//     });
//     navigate('/dashboard')
//   } catch (err) {
//     localStorage.clear()
//     console.log("err=>", err);
//     dispatch({
//       type: GET_USER_FAIL,
//       payload: err.response.data.message,
//     });
//     navigate('/')
//     console.log(localStorage)
//   }
// };

  