import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import EmployeeHome from './pages/employeePages/EmployeeHome'
import "./assets/css/bootstrap-datetimepicker.min.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/dataTables.bootstrap4.min.css"
import "./assets/css/font-awesome.min.css"
import "./assets/css/fullcalendar.min.css"
import "./assets/css/jquery.circliful.css"
import "./assets/css/line-awesome.min.css"
import "./assets/css/morris.css"
import "./assets/css/select2.min.css"
import "./assets/css/style.css"
import "./assets/css/tagsinput.css"
import EmployeeSchedule from './pages/employeePages/EmployeeSchedule'
import EmployeeAttendance from './pages/employeePages/EmployeeAttendance'
import EmployeeLeaves from './pages/employeePages/EmployeeLeaves'
import EmployeeTraining from './pages/employeePages/EmployeeTraining'
import EmployeeActivities from './pages/employeePages/EmployeeActivities'
import Inbox from './pages/Inbox'
import Profile from './pages/Profile'
import EmployeeSettings from './pages/employeePages/EmployeeSettings'
import EmployeeCalendar from './pages/employeePages/EmployeeCalendar'
import EmployeeTimesheet from './pages/employeePages/EmployeeTimesheet'
import EmployeeOvertime from './pages/employeePages/EmployeeOvertime'
import MailView from './pages/MailView'
import EmployeeWorkLocation from './pages/employeePages/EmployeeWorkLocation'
import EmployeeFileManager from './pages/employeePages/EmployeeFileManager'
import "./App.css"
import EmployeeLogin from './pages/EmployeeLogin';
import { useSelector } from 'react-redux'
import Delaypage from './pages/employeePages/Delaypage'
import Unauthorized from './pages/Unauthorized'


// import AdminDashboard from './pages/adminPages/AdminDashboard'

const App = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route path="/login" element={<EmployeeLogin />} /> 
        <Route path="/" element={<EmployeeLogin />} /> 
        <Route path="/checkUser" element={<Delaypage />} /> 
        { auth.user &&
          auth.user.role === "user" &&
          <>
          <Route path="/dashboard" element={<EmployeeHome />} /> 
          <Route path="/shift-scheduling" element={<EmployeeSchedule />} />
          <Route path="/attendance" element={<EmployeeAttendance />} />
          <Route path="/leaves" element={<EmployeeLeaves />} />
          <Route path="/training" element={<EmployeeTraining />} />
          <Route path="/activities" element={<EmployeeActivities />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<EmployeeSettings />} />
          <Route path="/calendar" element={<EmployeeCalendar />} />
          <Route path="/timesheet" element={<EmployeeTimesheet />} />
          <Route path="/overtime" element={<EmployeeOvertime />} />
          <Route path="/mail-view" element={<MailView />} />
          <Route path="/location-view" element={<EmployeeWorkLocation />} />
          <Route path="/file-manager" element={<EmployeeFileManager />} /> 
          </>
        }

        { auth.user &&
          auth.user.role === "admin" &&
          <>
          <Route path="/dashboard" element={<Delaypage />} /> 
          </>
        }
        {/* <Route path="*" element={<Unauthorized/>}/> */}
      </Routes>
    </>
  )
}

export default App
