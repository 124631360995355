import React from 'react'
import EmployeeHeader from '../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../components/employeeComponents/EmployeeTitle'
import ProfileBar from '../components/profileComponents/ProfileBar'
import ProfileCover from '../components/profileComponents/ProfileCover'

const Profile = () => {
    return (
        <>
            <EmployeeHeader />
            <EmployeeSidebar />
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="Profile" />
                        <ProfileCover />
                        <ProfileBar />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile