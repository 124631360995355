import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import LocationDescription from '../../components/employeeComponents/employeeLocationView/LocationDescription'
import LocationLeaders from '../../components/employeeComponents/employeeLocationView/LocationLeaders'
import LocationMap from '../../components/employeeComponents/employeeLocationView/LocationMap'
import LocationProjectDetails from '../../components/employeeComponents/employeeLocationView/LocationProjectDetails'
import LocationGuards from '../../components/employeeComponents/employeeLocationView/LocationGuards'
import LocationUploadedFiles from '../../components/employeeComponents/employeeLocationView/LocationUploadedFiles'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeWorkLocation = () => {
    return (
        <>
            <EmployeeHeader />
            <EmployeeSidebar />
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="Location" />
                        <div className="row">
                            <div className="col-lg-8 col-xl-9">
                                <LocationDescription />
                                <LocationMap />
                                <LocationUploadedFiles />
                            </div>

                            <div className="col-lg-4 col-xl-3">
                                <LocationProjectDetails />
                                <LocationLeaders />
                                <LocationGuards />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeWorkLocation