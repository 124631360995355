import axios from "axios";
import {
    OVERTIME_REQUEST,
    OVERTIME_SUCCESS,
    OVERTIME_FAIL,

    OVERTIME_TABLE_REQUEST,
    OVERTIME_TABLE_SUCCESS,
    OVERTIME_TABLE_FAIL,
    SERVERADDRESS,
} from "../constants"

// CREATE OVERTIME 
export const overtimeCardsEmployee = (obj) => async (dispatch) => {
    dispatch({
        type: OVERTIME_REQUEST,
    });
    try {
        // dkhioooooooooooo
        const { data } = await axios.post(`${SERVERADDRESS}/v1/overtimeShift/overtimeCardsEmployee`, obj);
        localStorage.setItem("overtime", JSON.stringify(data));
        dispatch({
            type: OVERTIME_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: OVERTIME_FAIL,
            payload: err,
        });
    }
};

// CREATE OVERTIME TABLE
export const getOvertimeShiftByUser = (obj) => async (dispatch) => {
    dispatch({
        type: OVERTIME_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/overtimeShift/getOvertimeShiftByUser`, obj);
        localStorage.setItem("overtimeTable", JSON.stringify(data));
        dispatch({
            type: OVERTIME_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: OVERTIME_TABLE_FAIL,
            payload: err,
        });
    }
};