import axios from "axios";
import {
    TRAINING_REQUEST,
    TRAINING_SUCCESS,
    TRAINING_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY TRAINING
export const getAllTraining = (obj) => async (dispatch) => {
    dispatch({
        type: TRAINING_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/training/getAllTraining`, obj);
        localStorage.setItem("training", JSON.stringify(data));
        dispatch({
            type: TRAINING_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: TRAINING_FAIL,
            payload: err,
        });
    }
};

