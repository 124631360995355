import {
    LEAVE_ANNUAL_STATUS_REQUEST,
    LEAVE_ANNUAL_STATUS_SUCCESS,
    LEAVE_ANNUAL_STATUS_FAIL,
    LEAVE_REQUEST,
    LEAVE_SUCCESS,
    LEAVE_FAIL,
    LEAVE_CREATE_REQUEST,
    LEAVE_CREATE_SUCCESS,
    LEAVE_CREATE_FAIL,
} from "../constants"

export const leaveReducer = (
    state = {
        leave: {
            leaveAnnualStatus: null,
            leaveList: null
        }
    },
    action
) => {
    switch (action.type) {
        // USER ANNUAL LEAVE STATUS
        case LEAVE_ANNUAL_STATUS_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_ANNUAL_STATUS_SUCCESS :
            return {
                ...state,
                leaveAnnualStatus: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case LEAVE_ANNUAL_STATUS_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER LEAVE LIST
        case LEAVE_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_SUCCESS :
            return {
                ...state,
                leaveList: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case LEAVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER LEAVE LIST
        case LEAVE_CREATE_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_CREATE_SUCCESS :
            return {
                ...state,
                leaveList: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case LEAVE_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}