import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileLocations from './ProfileLocations'
import ProfileMain from './ProfileMain'
import SalaryInfo from './SalaryInfo'

const ProfileBar = () => {
    const [ viewBarItems, setViewBarItems ] = useState("profile")
    const handleProfile = (e) => {
        setViewBarItems("profile")
    }
    const handleLocations = (e) => {
        setViewBarItems("locations")
    }
    const handleBank = (e) => {
        setViewBarItems("bank")
    }

    return (
        <>
            <div className="card tab-box">
                <div className="row user-tabs">
                    <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                            <li className="nav-item"><NavLink  to="#emp_profile" 
                            // data-toggle="tab"
                            onClick={handleProfile} 
                            className="nav-link active">Profile</NavLink ></li>
                            <li className="nav-item"><NavLink  to="#emp_Locations" 
                            // data-toggle="tab" 
                            onClick={handleLocations}
                            className="nav-link">Locations</NavLink ></li>
                            <li className="nav-item"><NavLink  to="#bank_statutory" 
                            // data-toggle="tab"
                            onClick={handleBank}
                            className="nav-link">Bank &amp; Statutory</NavLink ></li>
                        </ul>
                    </div>
                </div>
            </div>
            { viewBarItems === "profile" ?
                <ProfileMain />
                : viewBarItems === "locations" ?
                <ProfileLocations />
                : viewBarItems === "bank" ?
                <SalaryInfo />
                : <div></div>
            }
        </> 
    )
}

export default ProfileBar