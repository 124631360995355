import React from 'react'
import { overtimeCardsEmployee } from '../../../actions/overtimeActions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'

const OvertimeCard = () => {
        const auth = useSelector((state) => state.auth);
        const overtime = useSelector((state) => state.overtime);
     
        const dispatch = useDispatch()
        
         const overtimeData = async (e) => {
            const currDate = moment().format()
            const arr = currDate.split("+")
            const newCurrDate = arr[0]+".000+00:00"
             dispatch(overtimeCardsEmployee({userId: auth.user.id, date: newCurrDate}))
         }
     
        useEffect(() => {
           overtimeData()
       },[])

    return (
        <>
        
            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="stats-info">
                        <h6>Overtime</h6>
                        {
                        overtime.overtime &&
                        overtime.overtime.length > 0 &&
                        <h4>{overtime.overtime[0].overtimeThisMonth} <span>this month</span></h4>
                        }
                        {/* <h4>{{overtime.overtimeThisMonth}} <span>this month</span></h4> */}
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="stats-info">
                        <h6>Overtime Hours</h6>
                        {
                        overtime.overtime &&
                        overtime.overtime.length > 0 &&
                        <h4>{overtime.overtime[0].totalHours} <span>this month</span></h4>
                        }
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="stats-info">
                        <h6>Pending Request</h6>
                        {
                        overtime.overtime &&
                        overtime.overtime.length > 0 &&
                        <h4>{overtime.overtime[0].pending}</h4>
                        }
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="stats-info">
                        <h6>Rejected</h6>
                        {
                        overtime.overtime &&
                        overtime.overtime.length > 0 &&
                        <h4>{overtime.overtime[0].rejected}</h4>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default OvertimeCard