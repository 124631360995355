import React from 'react'
import EmployeeHeader from '../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../components/employeeComponents/EmployeeTitle'
import MessageBoxDesign from '../components/MessageBoxDesign'

const MailView = () => {
    return (
        <>
            <EmployeeHeader />
            <EmployeeSidebar />
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="View Message" />
                        <MessageBoxDesign />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MailView