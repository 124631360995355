import React from 'react'
import { NavLink } from 'react-router-dom'

const LocationGuards = () => {
    return (
        <>
            <div className="card project-user">
                <div className="card-body">
                    <h6 className="card-title m-b-20">Assigned Guards</h6>
                    <ul className="list-box">
                        <li>
                            <NavLink to="profile.html">
                                <div className="list-item">
                                    <div className="list-left">
                                        <span className="avatar"><img alt="" src="assets/img/profiles/avatar-02.jpg" /></span>
                                    </div>
                                    <div className="list-body">
                                        <span className="message-author">John Doe</span>
                                        <div className="clearfix"></div>
                                        <span className="message-content">Web Designer</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="profile.html">
                                <div className="list-item">
                                    <div className="list-left">
                                        <span className="avatar"><img alt="" src="assets/img/profiles/avatar-09.jpg" /></span>
                                    </div>
                                    <div className="list-body">
                                        <span className="message-author">Richard Miles</span>
                                        <div className="clearfix"></div>
                                        <span className="message-content">Web Developer</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LocationGuards