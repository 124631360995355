import {
    HOLIDAY_REQUEST,
    HOLIDAY_SUCCESS,
    HOLIDAY_FAIL,
} from "../constants"

export const holidayReducer = (
    state = {
        holiday: {
            holiday: null
        }
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case HOLIDAY_REQUEST : 
            return {
                ...state,
            };
        case HOLIDAY_SUCCESS :
            return {
                ...state,
                holiday: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case HOLIDAY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}