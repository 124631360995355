import React from 'react'
import avatar2 from "../../assets/img/avatar-02.jpg"
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeMain from '../../components/employeeComponents/employeeHome/EmployeeMain'

const EmployeeHome = () => {
   return (
      <>
      <EmployeeHeader />
      <EmployeeSidebar />
      <EmployeeMain />
      </>
   )
}

export default EmployeeHome