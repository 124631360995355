import React from 'react'
import EmployeeHeader from '../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../components/employeeComponents/EmployeeTitle'
import InboxMessages from '../components/InboxMessages'

const Inbox = () => {
  return (
    <>
      <EmployeeHeader />
      <EmployeeSidebar />
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeTitle title="Inbox" />
            <InboxMessages />
          </div>
        </div>
      </div>
    </>
  )
}

export default Inbox