import React from 'react'
import { NavLink } from 'react-router-dom'

const ProfileLeaves = () => {
  return (
    <>
      <div className="col-md-6 d-flex">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">Leaves</h3>
            <div className="table-responsive milevtb">
              <table className="table table-nowrap">
                <thead>
                  <tr>
                    <th>Leave Types</th>
                    <th className="text-center">Total Leave</th>
                    <th className="text-center">Remaining Leave</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Request Time Off (PTO)</strong>
                    </td>
                    <td className="text-center">10</td>
                    <td className="text-center">5</td>
                    <td className="text-center">
                      <div className="dropdown action-label">
                        <NavLink className="btn btn-white btn-sm btn-rounded dropdown-toggle" to="#" data-toggle="dropdown" aria-expanded="false">
                          <i className="fa fa-dot-circle-o text-success"></i> Enabled
                        </NavLink>
                        <div className="dropdown-menu dropdown-menu-right" style={{}}>
                          <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#approve_leave"><i className="fa fa-dot-circle-o text-success"></i> Enabled</NavLink>
                          <NavLink className="dropdown-item" to="#"><i className="fa fa-dot-circle-o text-danger"></i> Disabled</NavLink>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Vacation</strong>
                    </td>
                    <td className="text-center">10</td>
                    <td className="text-center">5</td>
                    <td className="text-center">
                      <div className="dropdown action-label">
                        <NavLink className="btn btn-white btn-sm btn-rounded dropdown-toggle" to="#" data-toggle="dropdown" aria-expanded="false">
                          <i className="fa fa-dot-circle-o text-success"></i> Enabled
                        </NavLink>
                        <div className="dropdown-menu dropdown-menu-right" style={{}}>
                          <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#approve_leave"><i className="fa fa-dot-circle-o text-success"></i> Enabled</NavLink>
                          <NavLink className="dropdown-item" to="#"><i className="fa fa-dot-circle-o text-danger"></i> Disabled</NavLink>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileLeaves