import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "./reducers/authReducer";
import { shiftReducer } from "./reducers/shiftReducer";
import { locationReducer } from "./reducers/locationReducer";
import { holidayReducer } from "./reducers/holidayReducer";
import { trainingReducer } from "./reducers/trainingReducer";
import { clockReducer } from "./reducers/clockReducer";
import { userReducer } from "./reducers/userReducer";
import { salaryReducer } from "./reducers/salaryReducer";
import { overtimeReducer } from "./reducers/overtimeReducer"
import { leaveReducer } from "./reducers/leaveReducer";
import { mailReducer } from "./reducers/mailReducer";

const initialState = {
    auth: {
        tokens: localStorage.getItem("tokens")
            ? JSON.parse(localStorage.getItem("tokens"))
            : null,
        user: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null,
        loading: false,        
    },
    shift: {
        dailyShift: localStorage.getItem("dailyShift")
            ? JSON.parse(localStorage.getItem("dailyShift"))
            : null,
        weeklyShift: localStorage.getItem("weeklyShift")
            ? JSON.parse(localStorage.getItem("weeklyShift"))
            : null,
        monthlyShift: localStorage.getItem("monthlyShift")
            ? JSON.parse(localStorage.getItem("monthlyShift"))
            : null,
        thisWeek: localStorage.getItem("thisWeek")
            ? JSON.parse(localStorage.getItem("thisWeek"))
            : null,
        tomorrowShift: localStorage.getItem("tomorrowShift")
            ? JSON.parse(localStorage.getItem("tomorrowShift"))
            : null,
        activeLocationThisWeek: localStorage.getItem("activeLocationThisWeek")
            ? JSON.parse(localStorage.getItem("activeLocationThisWeek"))
            : null,
        activeLocationThisMonth: localStorage.getItem("activeLocationThisMonth")
            ? JSON.parse(localStorage.getItem("activeLocationThisMonth"))
            : null,
        upcomingShift: localStorage.getItem("upcomingShift")
            ? JSON.parse(localStorage.getItem("upcomingShift"))
            : null,
        recentShift: localStorage.getItem("recentShift")
            ? JSON.parse(localStorage.getItem("recentShift"))
            : null,
        loading: false,
    },
    location: {
        location: localStorage.getItem("location")
            ? JSON.parse(localStorage.getItem("location"))
            : null,
    },
    holiday:{
        holiday: localStorage.getItem("holiday")
            ? JSON.parse(localStorage.getItem("holiday"))
            : null,
    },
    training:{
        training: localStorage.getItem("training")
            ? JSON.parse(localStorage.getItem("training"))
            : null,
    },
    clock:{
        clockData: localStorage.getItem("clockData")
            ? JSON.parse(localStorage.getItem("clockData"))
            : null,
        clockInButton: localStorage.getItem("clockInButton")
            ? JSON.parse(localStorage.getItem("clockInButton"))
            : true,
        breakButton: localStorage.getItem("breakButton")
            ? JSON.parse(localStorage.getItem("breakButton"))
            : null,
        clockStatusData: localStorage.getItem("clockStatusData")
            ? JSON.parse(localStorage.getItem("clockStatusData"))
            : null,
        clockMonthlyData: localStorage.getItem("clockMonthlyData")
            ? JSON.parse(localStorage.getItem("clockMonthlyData"))
            : null,
    },
    user:{
        annivarsary: localStorage.getItem("annivarsary")
            ? JSON.parse(localStorage.getItem("annivarsary"))
            : null,
    },
    salary:{
        salary: localStorage.getItem("salary")
            ? JSON.parse(localStorage.getItem("salary"))
            : null,
    },
    overtime:{
        overtime: localStorage.getItem("overtime")
            ? JSON.parse(localStorage.getItem("overtime"))
            : null,
        overtimeTable: localStorage.getItem("overtimeTable")
        ? JSON.parse(localStorage.getItem("overtimeTable"))
        : null,
    },
    leave:{
        leaveAnnualStatus: localStorage.getItem("leaveAnnualStatus")
            ? JSON.parse(localStorage.getItem("leaveAnnualStatus"))
            : null,
        leaveList: localStorage.getItem("leaveList")
            ? JSON.parse(localStorage.getItem("leaveList"))
            : null,
    },
    mail:{
        mailData: localStorage.getItem("mailData")
        ? JSON.parse(localStorage.getItem("mailData"))
        : null,
    }
};
// console.log("initialState", initialState)
const reducer = combineReducers({
    auth: authReducer,
    shift: shiftReducer,
    location: locationReducer,
    holiday: holidayReducer,
    training: trainingReducer,
    clock: clockReducer,
    user: userReducer,
    salary: salaryReducer,
    overtime: overtimeReducer,
    leave: leaveReducer,
    mail: mailReducer,
});

// const middleware = [thunk]

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
