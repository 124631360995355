import {
    SHIFT_DATA_REQUEST,
    SHIFT_DATA_SUCCESS,
    SHIFT_DATA_FAIL,
    SHIFT_DASBOARD_REQUEST,
    SHIFT_DASBOARD_SUCCESS,
    SHIFT_DASBOARD_FAIL,
    SHIFT_PROFILE_REQUEST,
    SHIFT_PROFILE_SUCCESS,
    SHIFT_PROFILE_FAIL,
    SHIFT_SEARCH_REQUEST,
    SHIFT_SEARCH_SUCCESS,
    SHIFT_SEARCH_FAIL,
} from "../constants"

export const shiftReducer = (
    state = {
        shift: {
            loading: false,
            dailyShift: {},
            weeklyShift: {},
            monthlyShift: {},
            thisWeek: {},
            tomorrowShift: {},
            activeLocationThisWeek: {},
            activeLocationThisMonth: {},
            upcomingShift: null,
            recentShift: null,
            searchData: null,
        },
    },
    action
) => {
    switch (action.type) {
        // SHIFT DATA
        case SHIFT_DATA_REQUEST : 
            return {
                ...state,
                loading: true,
                // dailyShift: null,
                // weeklyShift: null,
                // monthlyShift: null,
            };
        case SHIFT_DATA_SUCCESS :
            return {
                ...state,
                loading: false,
                dailyShift: action.payload[0].dailyShift,
                weeklyShift: action.payload[0].weeklyShift,
                monthlyShift: action.payload[0].monthlyShift,
            };
        case SHIFT_DATA_FAIL :
            return {
                ...state,
                loading: false,
                dailyShift: "Unable to get shift",
                weeklyShift: "Unable to get shift",
                monthlyShift: "Unable to get shift",
                error: action.payload,
            };
            case SHIFT_DASBOARD_REQUEST : 
            return {
                ...state,
            };
        case SHIFT_DASBOARD_SUCCESS :
            return {
                ...state,
                thisWeek: action.payload[0].thisWeek,
                tomorrowShift: action.payload[0].tomorrowShift,
                activeLocationThisWeek: action.payload[0].activeLocationThisWeek,
                activeLocationThisMonth: action.payload[0].activeLocationThisMonth,
            };
        case SHIFT_DASBOARD_FAIL :
            return {
                ...state,
                thisWeek: "Unable to get data",
                tomorrowShift: "Unable to get data",
                activeLocationThisWeek: "Unable to get data",
                activeLocationThisMonth: "Unable to get data",
                error: action.payload,
            };
        case SHIFT_PROFILE_REQUEST : 
            return {
                ...state,
            };
        case SHIFT_PROFILE_SUCCESS :
            return {
                ...state,
                upcomingShift: action.payload[0].upcoming,
                recentShift: action.payload[0].recent,
            };
        case SHIFT_PROFILE_FAIL :
            return {
                ...state,
                upcomingShift: "Unable to get data",
                recentShift: "Unable to get data",
                error: action.payload,
            };
        case SHIFT_SEARCH_REQUEST : 
            return {
                ...state,
            };
        case SHIFT_SEARCH_SUCCESS :
            return {
                ...state,
                searchData: action.payload[0],
            };
        case SHIFT_SEARCH_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}