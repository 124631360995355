import axios from "axios";
import {
    SALARY_REQUEST,
    SALARY_SUCCESS,
    SALARY_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER SALARY
export const getSalaryByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: SALARY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/salary/getSalaryByUserId`, obj);
        localStorage.setItem("salary", JSON.stringify(data));
        dispatch({
            type: SALARY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SALARY_FAIL,
            payload: err,
        });
    }
};