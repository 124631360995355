import React, { useEffect } from 'react'
// import { getUserAnnualStatusByUserId } from "../../../actions/leaveActions"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';

const LeavesCard = () => {
    const auth = useSelector((state) => state.auth);
    const leave = useSelector((state) => state.leave);
    // const dispatch = useDispatch()
    // const annualLeaveStatusData = async (e) => {
    //     dispatch(getUserAnnualStatusByUserId({userId: auth.user.id, date:  moment().format()}))
    // }
    // useEffect(() => {
    //     annualLeaveStatusData()
    // },[])
    return (
        <>
            <div className="row">
                {   
                    leave.leaveAnnualStatus &&
                        leave.leaveAnnualStatus.length > 0 &&
                            leave.leaveAnnualStatus.map((current, i) => {
                                return(
                                    <div className="col-md-3" key={i}>
                                        <div className="stats-info">
                                            <h6>{current.leaveCategory.name}</h6>
                                            <h4>{current.remainingLeavesDays}</h4>
                                        </div>
                                    </div>
                                )
                            })
                }
                {/* <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Medical Leave</h6>
                        <h4>3</h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Other Leave</h6>
                        <h4>4</h4>
                    </div>
                </div> */}
                {/* <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Remaining Leave</h6>
                        <h4>5</h4>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default LeavesCard