import {
    LOCATION_REQUEST,
    LOCATION_SUCCESS,
    LOCATION_FAIL,
} from "../constants"

export const locationReducer = (
    state = {
        location: {
            location: null,
        },
    },
    action
) => {
    switch (action.type) {
        // USER AUTH
        case LOCATION_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_SUCCESS :
            return {
                ...state,
                location: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case LOCATION_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}